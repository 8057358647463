
import { debug } from 'console';
import baseService from './base.service'


export function LoginUser(clientId:string,email:string,password:string) {
  
  var data={clientId:clientId,email:email,password:password};
  return baseService.post("/authentication/email", data);
}

export function GetUserInfo() {
  return baseService.get("/users/me",null);
}
