import { notificationsCircle } from 'ionicons/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller, FormProvider } from "react-hook-form";
import "../theme/home.css"
import { IonBackButton,IonIcon, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonItem, IonList, IonPage, IonTitle, IonToolbar, IonButton, IonGrid, IonCol } from "@ionic/react";
import { InputText } from "../editors/InputText";
import { GetUserInfo, LoginUser } from '../services/login.service';
//import { useAppDispatch } from '../redux/store';
import { loginAction } from '../redux/reducers/auth';
const Login = () => {
    //const dispatch = useAppDispatch()
    const onSubmitHandler = (data: any) => {
        
        // LoginUser("web" ,data.UserName,data.Password).then(res => {
            
        //    // dispatch(loginAction({name:'ali', token:res.accessToken}))
        //     GetUserInfo().then(res=>{
        //         debugger
        //        // dispatch(loginAction({name:'ali', token:res.accessToken}))
        //         //setdevicelist(res.data);
        //     })
            
        //   });
    }
    const formMethod = useForm();
    return (
        <IonPage>
            <IonHeader collapse='fade'>
                <IonToolbar>
                    <IonTitle size="large" >Login</IonTitle>
                   
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <FormProvider {...formMethod}>
                    <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container">
                        <IonList>
                            <IonItem>
                                <InputText label="User Name:" name="UserName" rules={{ required: "User Name is required" }} />
                            </IonItem>
                            <IonItem>
                                <InputText label="Password:" name="Password" rules={{ required: "Password is required" }} />
                            </IonItem>
                        </IonList>
                        <div className="form-actions">
                            <IonButton color="primary" type="submit">Login</IonButton>
                        </div>
                    </form>
                </FormProvider>

            </IonContent>
        </IonPage>
    );
}

export default Login;
