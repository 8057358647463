import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
//mport { UniqueDeviceID } from '@ionic-native/unique-device-id';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import PageRoute from './pages/PageRoute';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { setActiveTab } from './redux/actions/tabs';
import React from 'react';
import { GeneralContext } from './shared/GeneralContext';
import BaseService from '../src/services/base.service'
import { useAppDispatch, useAppSelector } from './redux/store';
import { loginAction, logoutAction } from './redux/reducers/auth';
import Login from './pages/login';
import { FormProvider, useForm } from 'react-hook-form';
import { GetUserInfo, LoginUser } from './services/login.service';
import { InputText } from './editors/InputText';
import { InputPassword } from './editors/InputPassword';
import Snackbar from 'react-native-snackbar';
import { calculator, helpCircle, home, image } from 'ionicons/icons';
import { Firebase } from './Firebase';
import { registerServiceWorker } from "./register-sw";
//import { compose, lifecycle, withState } from "recompose";



// constructor(private uniqueDeviceID: UniqueDeviceID) 
// { 
//    this.uniqueDeviceID.get()
//     .then((uuid: any) => console.log(uuid))
//     .catch((error: any) => console.log(error));
// }

// useEffect( () => {

//   const uid = await UniqueDeviceID.get()
//   .then((uuid: any) => console.log(uuid))
//   .catch((error: any) => console.log(error));
//   },[]);


setupIonicReact();
const App: React.FC<{ store: any, baseUrl: string, baseApiUrl: string, history: string ,token:string}> = (props) => {
  // constructor(private uniqueDeviceID: UniqueDeviceID) 
  // { 
  //    this.uniqueDeviceID.get()
  //     .then((uuid: any) => console.log(uuid))
  //     .catch((error: any) => console.log(error));
  // }
  
  BaseService.init(props.store, props.baseApiUrl);

  const auth = useAppSelector(s => s.auth);
  const dispatch = useAppDispatch()
  //const [showToast, setShowToast] = useState(props.showtoast)
  const [selectedTab, setSelectedTab] = useState('devices')
  const [token, settoken] = useState(props.token)


 

  useEffect(() => {
    setSelectedTab(localStorage.getItem('SelectedTab'))
  }, [])
  const onChangeTab = (activeTab: any) => {
    setSelectedTab(activeTab.detail.tab)
    localStorage.setItem('SelectedTab', activeTab.detail.tab);
  }

  const formMethod = useForm();
  const login = (data: any) => {
    dispatch(loginAction({ data }))

  }
  const logout = () => {
    dispatch(logoutAction())
  }

  const onSubmitHandler = (data: any) => {

    LoginUser("web", data.UserName, data.Password).then(res => {

      login({ name: 'ali', token: res.accessToken })
      GetUserInfo().then(res2 => {

        login({ name: res2.fullName.firstName, fullname: res2.fullName, lastname: res2.fullName.lastName, token: res.accessToken })

      })

    });
  }
  // if (!auth.token) {
  //   return (

  //     <>
  //       <IonApp>

  //         <IonReactRouter>
  //           {/* <Login></Login> */}
  //           <IonPage>
  //             <IonHeader collapse='fade'>
  //               <IonToolbar>
  //                 <IonTitle size="large" >Login</IonTitle>

  //               </IonToolbar>
  //             </IonHeader>
  //             <IonContent >
  //               <FormProvider {...formMethod}>
  //                 <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container">
  //                   <IonList>
  //                     <IonItem>
  //                       <InputText label="User Name:" name="UserName" rules={{ required: "User Name is required" }} />
  //                     </IonItem>
  //                     <IonItem>
  //                       {/* <IonInput  name="Password" type="password" > </IonInput> */}
  //                       <InputPassword label="Password:" name="Password" type="password" rules={{ required: "Password is required" }} />
  //                     </IonItem>
  //                   </IonList>
  //                   <div className="form-actions">
  //                     <IonButton color="primary" type="submit">Login</IonButton>
  //                   </div>
  //                 </form>
  //               </FormProvider>

  //             </IonContent>
  //           </IonPage>
  //         </IonReactRouter>
  //       </IonApp>

  //     </>
  //   )

  // }
  registerServiceWorker();
  const n =  Firebase();
  
  //const serverkey = "AAAA9VTiNyE:APA91bGzoX4grTE-2Wcy2f3fPrrTDSEm86nRTLV5uUjJEkx1w0n8MrqE-MC5CKL6xje7MQrz9a9RNrFIZoyrJqJzc7MWvt-uB8e8Hhssg1o5qzAqkgTO7vCgmNdRoJ3bha7ILA8EYdrf";
  function bodyParameter(token:any) {
   // debugger
    if(token && token!=""){
      const collection = {
        "notification": {
          "title": "Firebase 1",
          "body": "Firebase is awesome",
          "click_action": "http://localhost:3000/",
          "icon": "http://url-to-an-icon/icon.png"
        },
        "to": token
      };
      return collection;
    }
    
    return null;
  };
  return (
    <IonApp>
      
    {/* <Firebase> </Firebase> */}
    {/* <meta name="apple-mobile-web-app-capable" content="yes">
    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"></meta>
    </meta> */}
   
      {/* <Toastr showToast={showToast}></Toastr> */}
      <IonReactRouter>
        <IonTabs onIonTabsDidChange={onChangeTab}>
          <IonRouterOutlet>
            <PageRoute />
          </IonRouterOutlet>
          {/* <div><b>Body Parameter:</b><br /><b></b><div><pre>{JSON.stringify(bodyParameter(token), null, 2) }</pre></div></div> */}
          <IonTabBar slot="bottom" color="primary">
            <IonTabButton selected={'devices' == selectedTab} tab="devices" href="/devices">
              <IonLabel className='bottom-menu'>
                
                <div className='menu-image'>
                  {/* <IonIcon className="menu-actions" slot="icon-only" icon={image} /> */}
                  <img src="/assets/icon/Device.png" />
                </div>
                <div >
                Devices
                </div>
              </IonLabel>
            </IonTabButton>
            <IonTabButton selected={'home' == selectedTab} tab="home" href="/home">
              <IonLabel className='bottom-menu'>
              <div className='menu-image'>
                  {/* <IonIcon className="menu-actions" slot="icon-only" icon={home} /> */}
                  <img src="/assets/icon/Home.png" />
                </div>
                <div >
                Home
                </div>
                </IonLabel>
            </IonTabButton>
            <IonTabButton selected={'calculation' == selectedTab} tab="calculation" href="/calculation">
              <IonLabel className='bottom-menu'>
                
              <div className='menu-image'>
                  {/* <IonIcon className="menu-actions" slot="icon-only" icon={calculator} /> */}
                  <img src="/assets/icon/Calculation.png" />
                </div>
                <div >
                Calculation
                </div>
                </IonLabel>
            </IonTabButton>
            <IonTabButton selected={'disclaimer' == selectedTab} tab="disclaimer" href="/disclaimer">

              <IonLabel className='bottom-menu'>
                <div className='menu-image' >
                  {/* <IonIcon className="menu-actions" slot="icon-only" icon={helpCircle} /> */}
                  <img src="/assets/icon/Disclaimer.png" />
                </div>
                <div >
                  Disclaimer
                </div>
              </IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
      
    </IonApp>

  );
}

export default App;
