import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import { persistStore } from 'redux-persist';
import { PersistGate } from "redux-persist/integration/react";
import store,{persistor} from './redux/store';
//import { initializeFirebase } from './push-notification';
// import { registerServiceWorker } from "./register-sw";

// import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
// import LinearProgress from '@mui/material/LinearProgress'
// import useFirebaseMessaging from '@useweb/firebase/useFirebaseMessaging'
// import Text from '@useweb/ui/Text'
//import useSnackbar from '@useweb/ui/Snackbar'

//import CopyToClipboard from '../../lib/components/basic/CopyToClipboard/CopyToClipboard'


const { PUBLIC_URL } = process.env;
const { ENV } = process.env;
//let persistor = persistStore(store);


const render = () => {
 //registerServiceWorker(); 
 //initializeFirebase();
// const snackbar = useSnackbar()
// const FirebaseMessaging =
//  //const h=
//  useFirebaseMessaging({
//   onMessage: (message) => {
//     console.log(`Received foreground message`, message)
//    //  snackbar.show({
//    //    message: message?.notification?.title || message?.data?.title,
//    //  })
//   },
// })

  ReactDOM.render(
    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        {/* <div>{firebaseMessaging.fcmRegistrationToken}</div>  */}
      {/* <div>
      <Text
        text='Firebase Messaging Push Notification Example'
        sx={{ fontSize: '21px', fontWeight: 'bold' }}
      />

      {firebaseMessaging.initializing && (
        <>
          <Text
            text='Initializing Firebase Messaging (enable notifications for this page)'
            sx={{ mb: 2 }}
          />
          
        </>
      )}

      {firebaseMessaging.error && (
        <Text text={firebaseMessaging.error.toString()} sx={{ color: 'red' }} />
      )}

      {firebaseMessaging.fcmRegistrationToken && (
        <>
          <div
            
          >
            <Text text='FCM Registration Token:' />
           
          </div>

          <Text
            text={firebaseMessaging.fcmRegistrationToken}
            sx={{
              width: '100%',
              overflowWrap: 'break-word',
              fontSize: '14px',
              color: 'grey.main',
            }}
          />
        </>
      )}
    </div> */}
    
      <App
        store={store}
        // persistor={persistor}
        // basename={PUBLIC_URL}
        baseUrl=''
        baseApiUrl='https://api.brewwizzdata.onesmartview.com/'//'https://api.brewwizzdata.onesmartview.com/'//https://api.brewwizzdata-dev.onesmartview.com/'//https://api.brewwizzdata-dev.onesmartview.com'//'https://api.brewwizzdata.onesmartview.com/'//http://localhost:3100/'//{ENV.REACT_APP_API_AUTH} //'http://localhost:28918/' // http://vidacomp.ir:8090/  /http://localhost:28918/
        // theme={devTheme}
        history=''
        token=''
      />
      </PersistGate>
     
    </Provider>
    ,
    document.getElementById('root')
  );
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
