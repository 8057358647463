import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";



export const CO2Chart = (props: any) => {
    // const [co2value,setco2value]=useState(null);

    const { deviceid, co2value } = props;
    const [showbadge, setshowbadge] = useState(true)

    // const changebadge = () => {
    //     debugger
    //     //var oriention = window.screen.orientation;
    //     //var oriention = window.screen.orientation;
    //     if (window && window?.screen && window?.screen?.width && window.screen.width <600) {
    //         setshowbadge(true)
    //     }else{
    //         setshowbadge(false)
    //     }
    // }

    useEffect(()=>{
        
       // changebadge()
        //  //var oriention = window.screen.orientation;
        //  if (window && window?.screen && window?.screen?.width ) {
        // window.screen.orientation.onchange = function (e) {

        //     changebadge()
        // }
   // }
    },[])
    
    useEffect(() => {
        // if(co2value==null){
        //     co2value=0;
        // }
        // if(deviceid){
        //     /*get batch list for current device */

        // }

    }, [])

    am4core.useTheme(am4themes_animated);

    useEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.GaugeChart);
        chart.innerRadius = -20;//am4core.percent(90);
        am4core.useTheme(am4themes_animated);
        // chart.marginRight =10
        // chart.marginLeft = 10
        // chart.paddingLeft = 10
        // chart.paddingRight = 10
        chart.paddingTop = 0
        chart.startAngle = -260
        chart.endAngle = 80
        //chart.innerRadius = -20;

        let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis2.min = 0;
        axis2.max = 50;
        axis2.strictMinMax = true;
        axis2.renderer.minGridDistance = 100;
        axis2.renderer.inside = true;
        axis2.renderer.radius = am4core.percent(97);
        //axis2.renderer.radius = 80;
        axis2.renderer.line.strokeOpacity = 10;
        axis2.renderer.line.strokeWidth = 5;
        axis2.renderer.labels.template.radius = 35;
        //axis2.renderer.ticks.template.strokeOpacity = 1;
        //axis2.renderer.grid.template.disabled = true;
        axis2.renderer.ticks.template.length = 10;
        axis2.hiddenState.properties.opacity = 1;
        axis2.hiddenState.properties.visible = true;
        axis2.setStateOnChildren = true;
        axis2.renderer.hiddenState.properties.endAngle = 180;

        let range0 = axis2.axisRanges.create();
        range0.value = 0;
        range0.endValue = 20;
        range0.axisFill.fillOpacity = 10;
        range0.axisFill.fill = am4core.color('#1e8ab1');
        range0.axisFill.zIndex = -1;

        let range1 = axis2.axisRanges.create();
        range1.value = 20;
        range1.endValue = 30;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color('#FFD500');
        range1.axisFill.zIndex = -1;

        let range2 = axis2.axisRanges.create();
        range2.value = 30;
        range2.endValue = 40;
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = am4core.color('#11AE0C');
        range2.axisFill.zIndex = -1;

        let range3 = axis2.axisRanges.create();
        range3.value = 40;
        range3.endValue = 50;
        range3.axisFill.fillOpacity = 1;
        range3.axisFill.fill = am4core.color('#ff8800');
        range3.axisFill.zIndex = -1;

        // let label = chart.radarContainer.createChild(am4core.Label);
        // label.isMeasured = false;
        // label.fontSize = 25;
        // label.horizontalCenter = "middle";
        // label.verticalCenter = "bottom";
        let hand = chart.hands.push(new am4charts.ClockHand());

        hand.fill = axis2.renderer.line.stroke;
        hand.stroke = axis2.renderer.line.stroke;
        hand.axis = axis2;
        hand.pin.radius = 8;
        hand.startWidth = 8;


        //     hand.axis = axis2;
        //     hand.innerRadius=40
        //     hand.pin.disabled=true
        //    // hand.disabled=true;
        //     hand.startWidth = 0;

        hand.value = co2value;

        // hand.events.on("propertychanged", function (ev) {
        //     range0.endValue = ev.target.value;
        //     range1.value = ev.target.value;
        //     range3.value = ev.target.value;
        //     range2.value = ev.target.value;
        //     label.text = axis2.positionToValue(hand.currentPosition).toFixed(0);
        //     axis2.invalidate();
        // });
        new am4core.Animation(hand, {
            property: "value",
            from: 0,
            to: co2value
        }, 1000, am4core.ease.cubicOut).start();

        var legend = new am4charts.Legend();
        legend.markers.template.disabled = true;
        legend.isMeasured = false;
        legend.y = am4core.percent(60);
        legend.x = am4core.percent(0);
        legend.verticalCenter = "middle";
        legend.parent = chart.chartContainer;
        legend.data = [{
            "name": "PSI",
            "fill": chart.colors.getIndex(0)
        }];
        // var labelList = new am4core.ListTemplate(new am4core.Label());
        // labelList.template.isMeasured = false;
        // labelList.template.background.strokeWidth = 2;
        // labelList.template.fontSize = 25;
        // labelList.template.padding(10, 20, 10, 20);
        // labelList.template.y = am4core.percent(80);
        //labelList.template.x = am4core.percent(0);
        //labelList.template.horizontalCenter = "left";
        // label.horizontalCenter = "middle";
        //labelList.template.verticalCenter = "bottom";

if(showbadge) {
        var label=chart.radarContainer.createChild(am4core.Label);

        label.fontSize = 25;
        label.horizontalCenter = "middle";

        label.parent = chart.chartContainer;
        label.y = am4core.percent(55);
        label.verticalCenter = "middle";
        label.x = am4core.percent(50);
        label.background.stroke = chart.colors.getIndex(0);
        label.fill = chart.colors.getIndex(0);
        label.text = co2value;
        label.padding(4, 8, 4, 8);
        chart.events.on('sizechanged', () => {
            //label.scale = container.bbox.height / label.bbox.height;

            label.x = am4core.percent(50);
           });
    }
        var legend = new am4charts.Legend();
        legend.isMeasured = false;
        legend.y = am4core.percent(100);
        legend.verticalCenter = "bottom";
        legend.parent = chart.chartContainer;

        // legend.itemContainers.template.events.on("hit", function(ev) {
        //     var index = ev.target.dataItem.index;
        //     if (ev.target.isActive) {
        //       chart.hands.getIndex(index).show();
        //       chart.xAxes.getIndex(index).show();
        //       labelList.getIndex(index).show();
        //     }
        //     else {
        //       chart.hands.getIndex(index).hide();
        //       chart.xAxes.getIndex(index).hide();
        //       labelList.getIndex(index).hide();
        //     }
        //   });

    }, [co2value,showbadge])
    return (
        <>
            <div style={{ background: "white" }}>
                <IonRow>
                    <IonCol size="1">
                        <img src="/assets/images/Co2.png" style={{ minWidth: "35px" }} />
                    </IonCol>
                    <IonCol size={!showbadge ? "7":"9"}>
                    {!showbadge  ?
                        <div className="chart-container" id="chartdiv" style={{float:"right"}}></div>
                        :
                        <div className="chart-container" id="chartdiv" ></div>
                        }


                    </IonCol>
                   {!showbadge &&
                        <IonCol size="3">
                        <IonItem className="custom-border" >
                            <IonCol size="6">
                                <IonLabel position="fixed" class="ion-text-wrap" className="c-value">{co2value}</IonLabel>
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel position="fixed" class="ion-text-wrap" className="chart-icon">PSI</IonLabel>
                            </IonCol>
                        </IonItem>
                       
                    </IonCol>
                    }
                </IonRow>
            </div>

        </>

    )
}