import { createReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";



// Define a type for the slice state
interface AuthState {
    token: string
}

// Define the initial state using that type
const initialState: AuthState = {
    token: null
}

export const authSlice = createSlice({
    name: 'counter',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        loginAction: (state, action: PayloadAction<any>) => {
            state.token = action.payload.data.token
        },
        logoutAction: (state) => {
            state.token = null
        }
    }
})

export const { loginAction, logoutAction } = authSlice.actions



export const authReducer = authSlice.reducer