import { createAction } from "@reduxjs/toolkit";

interface stateType {
    selectedTab: string
};

export const setActiveTab = createAction("ACTIVETAB", function prepare(type: stateType) {
    return {
        payload: type
    };
});

// const initialState = {
//     activeTab: 'devices'
// };

// export const ActiveTabReducer = createReducer(initialState, {
//     [setActiveTab.type]: (state, action) => ({
//         ...state,
//         activeTab: { ...action.payload }
//     })
// });