import { IonCard, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';



const Disclaimer = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Disclaimer</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='disclaimer'>
        {/* <div className='disclaimer-icon'>
        <IonIcon icon={informationCircle}></IonIcon>
        </div> */}
        <IonCard>
          <IonItem class="device-list">
           <IonCol size="12" class='text-align-left'>
           <p>Calculations and formulas provided in this app have been compiled and supplied for your ease of use. It is up to the user to review, interpret and decide the suitability of the provided calculations and formulas within their own process.</p>
           <p>BrewWizz and associate partners hold/take no responsibility of any kind for any miss fortune or loss related to the use of this app.</p>
           <p className='no-padding-bottom'>Any feedback on the app please email to:</p>
        <a>info@brewwizz.com</a>
       
           </IonCol>
           
            
        
        
      

          </IonItem>
          
        </IonCard>
        
      </IonContent>
    </IonPage>
  );
};
export default Disclaimer;
