import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form"
import { Link } from "react-router-dom";
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";

const CarbonationLevel = () => {
    const formMethod = useForm();
    const[result,setresult]=useState("");
     const[TemperatureC,setTemperatureC]=useState(null);
     const[TemperatureF,setTemperatureF]=useState(null);
    
    const showtempretarec = formMethod.watch("TemperatureC", null)
    const showtempretaref = formMethod.watch("TemperatureF", null)
    const pressure = formMethod.watch("Pressure", null)
    useEffect(() => {
        let calcresult="";
        
        if(TemperatureC != showtempretarec){
            let calcresult="";
                if(showtempretarec ){
                   calcresult=((showtempretarec*9/5)+32).toFixed(2);
                }
                
                setTemperatureF(calcresult);
                setTemperatureC(showtempretarec)
                formMethod.setValue("TemperatureF", calcresult)
        }
        else if(TemperatureF != showtempretaref){
            let calcresult="";
                 if(showtempretaref ){
                   calcresult=((showtempretaref-32)*5/9).toFixed(2);              
                 }
                
                setTemperatureC(calcresult);
                setTemperatureF(showtempretaref)
                formMethod.setValue("TemperatureC", calcresult)
        }
        
        if(showtempretaref && pressure){
            
            let h=(parseFloat(pressure)+14.695);
            let s=(0.01821+0.090115*(2.71 ** ((32-parseFloat(showtempretaref))/43.11)));
           calcresult=(h*(s)-0.003342).toFixed(2);
        }
        setresult(calcresult);
       
      }, [showtempretaref,pressure,showtempretarec])
    //   useEffect(() => {
    //     let calcresult="";
    //     if(showtempretaref ){
    //        calcresult=((showtempretaref-32)*5/9).toFixed(2);
    //     }
        
    //     setTemperatureC(calcresult);
    //     formMethod.setValue("TemperatureC", calcresult)
       
    //   }, [showtempretaref])
    //   useEffect(() => {
    //     let calcresult="";
    //     if(showtempretaref ){
    //        calcresult=((showtempretaref*9/5)+32).toFixed(2);
    //     }
        
    //     setTemperatureF(calcresult);
    //     formMethod.setValue("TemperatureF", calcresult)
       
    //   }, [showtempretarec])
    const onSubmitHandler = (data: any) => {
        //debugger
    }
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar  style={{ display: "flex" }}>
                <div style={{float:"left"}}>
                    <Link to="/calculation">
                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                    </Link>
                    </div>
                <div style={{ flex: "1" }}>
                                      <IonTitle>Carbonation level checker</IonTitle>
                                      </div>
                </IonToolbar>
                {/* <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/calculation" />
                    </IonButtons>
                    <IonTitle>Carbonation level checker</IonTitle>
                </IonToolbar> */}
            </IonHeader>
            <IonContent fullscreen >
                <div className="flex-content">
                    <IonList className="no-padding" >
                        <FormProvider {...formMethod} >
                            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{background:"white"}}>
                                <div className="list-subheader">Please specify below parameters(Type of beverage is Beer):</div>
                                {/* <IonItem > */}
                                    <InputText label="Temperature(F):" name="TemperatureF"  />
                                {/* </IonItem> */}
                              
                                {/* <IonItem class="disable"> */}
                                    <InputText label="Temperature(C):" name="TemperatureC"  />
                                {/* </IonItem> */}
                              
                                {/* <IonItem> */}
                                    <InputText label="Pressure(PSI):" name="Pressure" />
                                {/* </IonItem> */}
                                <div className="calc-result-box">
                                <IonItem className="withoutborder whiteclass"  style={{color:"white"}} >
                                    <IonCol size="12">
                                        Result
                                    </IonCol>
                                    </IonItem>
                                <IonItem className="white-border" >
                               
                                    <IonCol size="6">
                                        <IonLabel position="fixed">Volumes</IonLabel>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="fixed">{result}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                </div>
                            </form>
                        </FormProvider>
                    </IonList>
                    <div>
                        <Devider />
                        <div className="formula-container">
                            <img src="/assets/images/formula.PNG" />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
export default CarbonationLevel