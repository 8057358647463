import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form"
import { Link } from "react-router-dom";
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";

const BottleConditioning = () => {
    const formMethod = useForm();
    const beverageInLiter = formMethod.watch("BeverageInLiter", null)
    
    const farenheitTemp = formMethod.watch("FarenheitTemp", null)
    const celsiusTemp = formMethod.watch("CelsiusTemp", null)
    const beverage = formMethod.watch("Beverage", null)
    const desiredCarbonationVolume = formMethod.watch("DesiredCarbonationVolume", null)
    

    const[brownsuger,setbrownsuger]=useState("");
    const[honey,sethoney]=useState("");
    const[cornsuger,setcornsuger]=useState("");
    const[cornsyrup,setcornsyrup]=useState("");
    const[maplesyrup,setmaplesyrup]=useState("");
    const[psgram,setpsgram]=useState("");
    const[brownsugerInGram,setbrownsugerInGram]=useState("");
    const[honeyInGram,sethoneyInGram]=useState("");
    const[cornsugerInGram,setcornsugerInGram]=useState("");
    const[cornsyrupInGram,setcornsyrupInGram]=useState("");
    const[maplesyrupInGram,setmaplesyrupInGram]=useState("");
    const[TemperatureC,setTemperatureC]=useState(null);
    const[TemperatureF,setTemperatureF]=useState(null);

    const[GallonV,setGallonV]=useState(null);
    const[LiterV,setLiterV]=useState(null);

    useEffect(() => {
        
        let calcnumber = 0;
        let calcstring=""
        let psgram=0;
        
        /*this means that this data is changed and fareinheit should be calculated */
        if(TemperatureC != celsiusTemp){
            let calcresult="";
                if(celsiusTemp ){
                   calcresult=((celsiusTemp*9/5)+32).toFixed(2);
                }
                
                setTemperatureF(calcresult);
                setTemperatureC(celsiusTemp)
                formMethod.setValue("FarenheitTemp", calcresult)
        }

        
  /*this means that this data is changed and gallon should be calculated */
        if(LiterV != beverageInLiter){
            let calcresult="";
            
                if(beverageInLiter ){
                   calcresult=((beverageInLiter/ 3.78541)).toFixed(2);
                }
                
                setGallonV(calcresult);
                setLiterV(beverageInLiter)
                formMethod.setValue("Beverage", calcresult)
        }

        if(celsiusTemp && beverageInLiter && desiredCarbonationVolume ){
            var calctemp=(parseFloat(celsiusTemp)*(9/5))+32;
          //  psgram=15.195*parseFloat(beverageInLiter)*0.264172*(parseFloat(desiredCarbonationVolume)-3.0378+(0.050062*calctemp)-(0.00026555 * (calctemp*calctemp)));
            psgram=15.195*beverageInLiter*(desiredCarbonationVolume-3.0378 +(0.050062 *calctemp)-(0.00026555 * Math.pow(calctemp,2)));
            debugger
            calcnumber=psgram*0.913;
            calcstring=calcnumber.toFixed(2)
            setbrownsuger(calcstring);
    
            calcnumber=psgram*1.1634;
            calcstring=calcnumber.toFixed(2);
            sethoney(calcstring);

            calcstring=psgram.toFixed(2);
            setcornsuger(calcstring);

            calcnumber=psgram*1.49;
            calcstring=calcnumber.toFixed(2);
            setcornsyrup(calcstring);

            calcnumber=psgram*2.1473;
            calcstring=calcnumber.toFixed(2);
            setmaplesyrup(calcstring)            
        }           
       
       
      }, [beverageInLiter,desiredCarbonationVolume,celsiusTemp])


      useEffect(() => {
        
        let calcnumber = 0;
        let calcstring=""
        let psgram=0;
        

        if(TemperatureF != farenheitTemp){
            let calcresult="";
                 if(farenheitTemp ){
                   calcresult=((farenheitTemp-32)*5/9).toFixed(2);              
                 }
                
                setTemperatureC(calcresult);
                setTemperatureF(farenheitTemp)
                formMethod.setValue("CelsiusTemp", calcresult)
        }

        if(GallonV != beverage){
            let calcresult="";
            
                if(beverage ){
                   calcresult=((beverage* 3.78541)).toFixed(2);
                }
                
               
                setLiterV(calcresult)
                setGallonV(beverage);
                formMethod.setValue("BeverageInLiter", calcresult)
        }


        if(farenheitTemp && beverage && desiredCarbonationVolume ){
           
            psgram=15.195*beverage*(desiredCarbonationVolume-3.0378 +(0.050062 *farenheitTemp)-(0.00026555 * Math.pow(farenheitTemp,2)));
            debugger
            calcnumber=psgram*0.913;
            calcstring=calcnumber.toFixed(2)
            setbrownsugerInGram(calcstring);
    
            calcnumber=psgram*1.1634;
            calcstring=calcnumber.toFixed(2);
            sethoneyInGram(calcstring);

            calcstring=psgram.toFixed(2);
            setcornsugerInGram(calcstring);

            calcnumber=psgram*1.49;
            calcstring=calcnumber.toFixed(2);
            setcornsyrupInGram(calcstring);

            calcnumber=psgram*2.1473;
            calcstring=calcnumber.toFixed(2);
            setmaplesyrupInGram(calcstring)      
            
            
          
            setpsgram(psgram.toFixed(2));
        }           
       
       
      }, [beverage,desiredCarbonationVolume,farenheitTemp])

    const onSubmitHandler = (data: any) => {
       // debugger
    }
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar  style={{ display: "flex" }}>
                <div style={{float:"left"}}>
                    <Link to="/calculation">
                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                    </Link>
                    </div>
                <div style={{ flex: "1" }}>
                                      <IonTitle>Bottle Conditioning</IonTitle>
                                      </div>
                </IonToolbar>
               
            </IonHeader>
            <IonContent fullscreen >
                <div className="flex-content">
                    <IonList className="no-padding" >
                        <FormProvider {...formMethod}>
                            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container">
                                <div className="list-subheader">Please specify below parameters(Type of beverage is Beer):</div>
                                {/* <IonItem> */}
                                    <InputText class="ion-text-wrap" label="Beverage In Gallons:" name="Beverage" />
                                {/* </IonItem> */}
                                {/* <IonItem> */}
                                    <InputText class="ion-text-wrap" label="Beverage In Liters:" name="BeverageInLiter" />
                                {/* </IonItem> */}
                                {/* <IonItem> */}
                                    <InputText class="ion-text-wrap" label="Desired Carbonation Volumes:" name="DesiredCarbonationVolume" />
                                {/* </IonItem> */}
                                {/* <IonItem> */}
                                    <InputText label="Temperature of Beverage Fahrenheit:" name="FarenheitTemp" />
                                {/* </IonItem> */}
                                {/* <IonItem> */}
                                    <InputText label="Temperature of Beverage Celsius:" name="CelsiusTemp" />
                                {/* </IonItem> */}
                                <div className="calc-result-box">
                                <IonItem className="withoutborder whiteclass"  style={{color:"white"}}>
                                    <IonCol size="12">
                                        Result
                                    </IonCol>
                                    </IonItem>
                                <IonItem className="withoutborder result-header" >
                                    <IonCol size="4">
                                        <IonLabel  style={{background: "#1e8ab1"}} position="fixed">Sugar Source</IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonLabel  style={{background: "#1e8ab1"}} position="fixed">Grams</IonLabel>
                                    </IonCol>
                                   
                                    <IonCol size="4">
                                        <IonLabel  style={{background: "#1e8ab1"}} position="fixed">OZ</IonLabel>
                                    </IonCol>
                                    
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">Table or brown sugar:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{brownsugerInGram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{brownsugerInGram?(parseFloat(brownsugerInGram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">Honey</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{honeyInGram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{honeyInGram?(parseFloat(honeyInGram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">Corn sugar Dextrose:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{cornsugerInGram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{cornsugerInGram?(parseFloat(cornsugerInGram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">Corn syrup/Molasses/Candy sugar:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{cornsyrupInGram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel  class="ion-text-wrap" position="fixed">{cornsyrupInGram?(parseFloat(cornsyrupInGram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">Maple syrup:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{maplesyrupInGram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{maplesyrupInGram?(parseFloat(maplesyrupInGram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>

                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel class="ion-text-wrap" position="fixed">PS Grams:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{psgram}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel class="ion-text-wrap" position="fixed">{psgram?(parseFloat(psgram)*0.035274).toFixed(2):""}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                </div>
                            </form>
                        </FormProvider>
                    </IonList>
                    <div>
                        <Devider />
                        <div className="formula-container">
                            <img  src="/assets/images/bottleconditioning.png" />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
export default BottleConditioning