import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from "@ionic/react"
import { arrowBackCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";

const IBUTinseth = () => {
    const cols = [];
    const formMethod = useForm();
    const hope1 = formMethod.watch("hope1", null)
    const alph1 = formMethod.watch("AlphaAsid1", null)
    const BoilTime1 = formMethod.watch("BoilTime1", null)
    const Type1 = formMethod.watch("Type1", null)
    const [type1value, settype1value] = useState("");


    const hope2 = formMethod.watch("hope2", null)
    const alph2 = formMethod.watch("AlphaAsid2", null)
    const BoilTime2 = formMethod.watch("BoilTime2", null)
    const Type2 = formMethod.watch("Type2", null)
    const [type2value, settype2value] = useState("");

    const hope3 = formMethod.watch("hope3", null)
    const alph3 = formMethod.watch("AlphaAsid3", null)
    const BoilTime3 = formMethod.watch("BoilTime3", null)
    const Type3 = formMethod.watch("Type3", null)
    const [type3value, settype3value] = useState("");


    const hope4 = formMethod.watch("hope4", null)
    const alph4 = formMethod.watch("AlphaAsid4", null)
    const BoilTime4 = formMethod.watch("BoilTime4", null)
    const Type4 = formMethod.watch("Type4", null)
    const [type4value, settype4value] = useState("");

    const hope5 = formMethod.watch("hope5", null)
    const alph5 = formMethod.watch("AlphaAsid5", null)
    const BoilTime5 = formMethod.watch("BoilTime5", null)
    const Type5 = formMethod.watch("Type5", null)
    const [type5value, settype5value] = useState("");

    const hope6 = formMethod.watch("hope6", null)
    const alph6 = formMethod.watch("AlphaAsid6", null)
    const BoilTime6 = formMethod.watch("BoilTime6", null)
    const Type6 = formMethod.watch("Type6", null)
    const volume = formMethod.watch("Volume", null)
    //const VolumeInLiter = formMethod.watch("VolumeInLiter", null)
    const target = formMethod.watch("Target", null)
    const [type6value, settype6value] = useState("");



    const [hopename, sethopename] = useState("hope");
    const [volumelabel, setvolumelabel] = useState("Volume In Gallons:");
    const [weightr, setweightr] = useState("Leaf  by Weight(OZ)");

    // const[volumeG,setvolumeG]=useState("Hops by Weight(OZ)");
    const [result1, setresult1] = useState("");
    const [result2, setresult2] = useState("");
    const [result3, setresult3] = useState("");
    const [result4, setresult4] = useState("");
    const [result5, setresult5] = useState("");
    const [result6, setresult6] = useState("");
    const [resulttotal, setresulttotal] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const ChangeCalToLiter = (check: any) => {
        // toggle the state 'isChecked'
        // this makes it true if false and vice versa

        setIsChecked(!isChecked);
        if (check) {
            setvolumelabel("Volume In Liter:")
            setweightr("Leaf by Weight(gr):")
        } else {
            setvolumelabel("Volume In Gallons:")
            setweightr("Leaf by Weight(OZ):")
        }

    }

    function typechanged(index: any, value: any) {
        debugger
        switch (index.toString()) {
            case "1":
                debugger
                calculate(hope1, alph1, BoilTime1, value, 1, target, volume)
                settype1value(value)
                break;
            case "2":
                calculate(hope2, alph2, BoilTime2, value, 2, target, volume)
                settype2value(value)
                break;
            case "3":
                calculate(hope3, alph3, BoilTime3, value, 3, target, volume)
                settype3value(value)
                break;
            case "4":
                calculate(hope4, alph4, BoilTime4, value, 4, target, volume)
                settype4value(value)
                break;
            case "5":
                calculate(hope5, alph5, BoilTime5, value, 5, target, volume)
                settype5value(value)
                break;
            case "6":
                calculate(hope6, alph6, BoilTime6, value, 6, target, volume)
                settype6value(value)
                break;
        }

    }

    // useEffect(()=>{
    //     let calcresult="";

    //     if(VolumeInLiter && isChecked){
    //        calcresult=((VolumeInLiter/3.8)).toFixed(2);

    //        //setGallonV(calcresult);
    //        formMethod.setValue("Volume", calcresult)
    //     }

    // },[VolumeInLiter,isChecked])
    useEffect(() => {
        debugger
        if (hope1 && alph1 && BoilTime1 && target && volume)
            calculate(hope1, alph1, BoilTime1, type1value, 1, target, volume)
    }, [hope1, alph1, BoilTime1, type1value, target, volume, isChecked])
    useEffect(() => {
        if (hope2 && alph2 && BoilTime2 && target && volume)
            calculate(hope2, alph2, BoilTime2, type2value, 2, target, volume)
    }, [hope2, alph2, BoilTime2, type2value, target, volume, isChecked])
    useEffect(() => {
        if (hope3 && alph3 && BoilTime3 && target && volume)
            calculate(hope3, alph3, BoilTime3, type3value, 3, target, volume)
    }, [hope3, alph3, BoilTime3, type3value, target, volume, isChecked])

    useEffect(() => {
        if (hope4 && alph4 && BoilTime4 && target && volume)
            calculate(hope4, alph4, BoilTime4, type4value, 4, target, volume)
    }, [hope4, alph4, BoilTime4, type4value, target, volume, isChecked])


    useEffect(() => {
        if (hope5 && alph5 && BoilTime5 && target && volume)
            calculate(hope5, alph5, BoilTime5, type5value, 5, target, volume)
    }, [hope5, alph5, BoilTime5, type5value, target, volume, isChecked])

    useEffect(() => {

        if (hope6 && alph6 && BoilTime6 && target && volume)
            calculate(hope6, alph6, BoilTime6, type6value, 6, target, volume)
    }, [hope6, alph6, BoilTime6, type6value, target, volume, isChecked])

    useEffect(() => {

        let sum = 0;
        if (result1) {
            sum = sum + parseFloat(result1);
        }
        if (result2) {
            sum = sum + parseFloat(result2);
        }
        if (result3) {
            sum = sum + parseFloat(result3);
        }
        if (result4) {
            sum = sum + parseFloat(result4);
        }
        if (result5) {
            sum = sum + parseFloat(result5);
        }
        if (result6) {
            sum = sum + parseFloat(result6);
        }
        setresulttotal(sum.toFixed(2))
    }, [result1, result2, result3, result4, result5, result6])


    const onSubmitHandler = (data: any) => {
        // debugger
    }

    const calculate = (hopeitem: any, alpha: any, boiltime: any, type: any, rownumber: any, t: any, vv: any) => {
        debugger
        var hope = hopeitem;
        if (!vv || !t) {
            return false;
        }
        var v = vv;
        if (isChecked) {
            // In this case the gr should covert to oz
            hope = (hope / 28.35).toFixed(2);

            let calcresult = "";

            calcresult = ((v / 3.8)).toFixed(2);
            //setGallonV(calcresult);
            v = calcresult;
            //formMethod.setValue("Volume", calcresult)

        }
        let target = t;

        let typecoef = 1;
        if (type == "Pellets" || type == 2 || type == '2') {
            typecoef = 1.1
        }

        if (hope && alpha && boiltime) {
            let result = (75 * (parseFloat(hope) * parseFloat(alpha)) * (1.65) * Math.pow(0.000125, (target - 1)) * (((1 - Math.pow(2.718, (-0.04 * parseFloat(boiltime)))) / 4.15) / v)) * typecoef
            switch (rownumber) {
                case 1:
                    setresult1(result.toFixed(2).toString())
                    break;
                case 2:
                    setresult2(result.toFixed(2).toString())
                    break;
                case 3:
                    setresult3(result.toFixed(2).toString())
                    break;
                case 4:
                    setresult4(result.toFixed(2).toString())
                    break;
                case 5:
                    setresult5(result.toFixed(2).toString())
                    break;
                case 6:
                    setresult6(result.toFixed(2).toString())
                    break;
            }
        }


    }
    for (let i = 1; i < 7; i++) {
        // {sethopename("Hope"+i)}
        cols.push(<IonItem className="withoutborder">
            <IonRow key={i.toString()} >

                <IonCol key={"col" + i.toString()} size="2">
                    <IonLabel class=" small-font">Addition {i}</IonLabel>
                </IonCol>
                <IonCol className="buttom-border" key={"col1" + i.toString()} size="2">
                    <InputText customclass="withoutborder" key={"hope" + i.toString()} name={"hope" + i.toString()} />
                </IonCol>
                <IonCol className="buttom-border" key={"col2" + i.toString()} size="2">
                    <IonLabel ><InputText customclass="withoutborder" value="" key={"AlphaAsid" + i.toString()} name={"AlphaAsid" + i.toString()} /></IonLabel>
                </IonCol>
                <IonCol className="buttom-border" key={"col3" + i.toString()} size="2">
                    <IonLabel><InputText value="" customclass="withoutborder" key={"BoilTime" + i.toString()} name={"BoilTime" + i.toString()} /></IonLabel>
                </IonCol>
                <IonCol key={"col4" + i.toString()} size="4">
                    {/* <IonLabel> */}
                    {/* <InputText value=""  key={("Type"+i.toString())} name={("Type"+i.toString())} /> */}
                    <IonSelect className="item-fill-outline full-size"

                        onIonChange={(e) => {
                            //console.log(e);
                            typechanged(i, e.detail.value)
                            // setselectedDevice(e.detail.value)
                        }}
                        placeholder="Select Type"
                        key={("Type" + i.toString())} name={("Type" + i.toString())}
                    // value={selectedDevice} 
                    >
                        <IonSelectOption value="1">Leaf</IonSelectOption>

                        <IonSelectOption value="2">Pellets</IonSelectOption>


                    </IonSelect>
                    {/* <InputActionSheet key={("Type"+i.toString())} name={("Type"+i.toString())} 
                                        options={[{ value: "Hops", text: 'Hops' },
                                        { value: "Pellets", text: 'Pellets' }
                                       ]} /> */}


                </IonCol>
            </IonRow>
        </IonItem>);
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ display: "flex" }}>
                    <div style={{ float: "left" }}>
                        <Link to="/calculation">
                            <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                        </Link>
                    </div>
                    <div style={{ flex: "1" }}>
                        <IonTitle>IBU Tinseth</IonTitle>
                    </div>
                </IonToolbar>

            </IonHeader>
            <IonContent fullscreen >
                <div className="flex-content">
                    <div>

                        <FormProvider {...formMethod}>
                            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{ background: "white" }} >
                                <div className="list-subheader">
                                    International Bitterness Units:
                                    {/* <span style={{ 'color': 'white' }}>
                            (Target G:1.08;Volume:5 gallons)</span> */}
                                </div>
                                <IonRow>
                                    <IonCol size="4">
                                        <IonLabel className=''>Gallons or Liters    </IonLabel>
                                    </IonCol>
                                    <IonCol size="1">

                                        <IonToggle
                                            checked={isChecked}
                                            onIonChange={(e) => ChangeCalToLiter(e.detail.checked)}
                                        ></IonToggle>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="6">
                                        {/* <IonItem > */}
                                        <InputText class="ion-text-wrap" label="Target Specific Gravity:" name="Target" />

                                        {/* </IonItem> */}
                                    </IonCol>
                                    <IonCol size="6">
                                        {/* <IonItem > */}
                                        {/* {isChecked?
                                    <InputText label={volumelabel} name="VolumeInLiter"  />
                                    : */}
                                        <InputText label={volumelabel} name="Volume" />
                                        {/* } */}


                                        {/* </IonItem> */}
                                    </IonCol>
                                </IonRow>


                                <div className="list-subheader">Please specify below parameters(Type of beverage is Beer):</div>
                                <IonGrid className="grid-cellular">
                                    <IonItem className="grid-header grid-bg-yellow withoutborder">
                                        <IonRow>
                                            <IonCol size="4">{weightr}</IonCol>
                                            <IonCol size="2">Alpha Acid(%)</IonCol>
                                            <IonCol size="2">Boil Time(mints)</IonCol>
                                            <IonCol size="4">Type</IonCol>
                                        </IonRow>
                                    </IonItem>
                                    {cols}
                                </IonGrid>
                                <div className="list-subheader no-padding-bottom">Result:</div>
                                <IonItem className="calc-result-box withoutborder" >
                                    <IonGrid className="grid-cellular">
                                        <IonItem className="grid-header grid-bg-green">
                                            <IonRow>
                                                <IonCol>IBU1</IonCol>
                                                <IonCol>IBU2</IonCol>
                                                <IonCol>IBU3</IonCol>
                                                <IonCol>IBU4</IonCol>
                                                <IonCol>IBU5</IonCol>
                                                <IonCol>IBU6</IonCol>
                                                <IonCol>Total IBU</IonCol>
                                            </IonRow>
                                        </IonItem>
                                        <IonItem className="withoutborder">
                                            <IonRow>
                                                <IonCol style={{ 'background': 'white', 'color': 'black' }}>{result1}</IonCol>
                                                <IonCol>{result2}</IonCol>
                                                <IonCol>{result3}</IonCol>
                                                <IonCol>{result4}</IonCol>
                                                <IonCol>{result5}</IonCol>
                                                <IonCol>{result6}</IonCol>
                                                <IonCol>{resulttotal}</IonCol>
                                            </IonRow>
                                        </IonItem>
                                    </IonGrid>
                                </IonItem>
                            </form>
                        </FormProvider>
                    </div>
                    <div>
                        <Devider />
                        <div className="formula-container">
                            <img src="/assets/images/ibuttenith.png" />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
export default IBUTinseth