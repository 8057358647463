
import baseService from './base.service'


export function TelemetryList(req:any,batchid:string,deviceid:string) {
  return baseService.getwitherror("/batch/"+batchid+"/readings/all/"+deviceid,req);
}

export function BatchList(req:any,deviceid:string) {
  return baseService.get("/batch/app?deviceId="+deviceid,req);
}
export function create(data:any){
  return baseService.post("/batch", data);
}
