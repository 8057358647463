import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonRow, IonTitle, IonToggle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react";
import { GetFCMNotif, LocalList, NotifList } from "../../services/device.service";
import ReactHtmlParser from 'html-react-parser';
import { addCircle, arrowBackCircle, arrowForwardCircleOutline, checkmarkCircle, closeCircle, timeOutline, trashSharp } from "ionicons/icons";
import { FormProvider } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { InputText } from "../../editors/InputText";
import { time } from "console";

const Notification = () => {
    const [devicelist, setdevicelist] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setmessage] = useState("");
    
    const { serialnumber } =useParams<{ serialnumber: string }>();
    
    useEffect(() => {

        NotifList({ req: null},serialnumber ).then(res => {
            setdevicelist(res);
        });

        GetFCMNotif({ req: null }).then(res => {

        });
    }, [])

    const showmore = (content: any) => {
        setmessage(content)
        setIsOpen(true)
    }

    return (
        <>
            <IonPage>

                <IonContent fullscreen>

                    <IonCard>
                        <IonList>
                            <IonListHeader className="notif-header" style={{ display: "flex" }}>

                                <div  >

                                    <Link to={serialnumber!=null?"/Devices": "/home"}>
                                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                                    </Link>
                                </div>
                                <div style={{ flex: "1" }}>
                                    Notifications
                                </div>

                            </IonListHeader>
                            {devicelist && devicelist.length > 0 && devicelist.map(function (object, i) {
                                return <>

                                    <IonItem class="device-list" style={{ margin: "0px" }} key={object.uuid}>

                                        <IonRow style={{ margin: "0px" }}>
                                            <IonCol size="1" className="row-number">
                                                {i + 1}

                                            </IonCol>

                                            <IonCol size="10">
                                                <IonLabel key={object.uuid + "label"}>
                                                    <table className="withoutborder-table" key={object.uuid + "table"}>
                                                        <tr key={object.uuid + "tr"}>
                                                            <td key={object.uuid + "td"}>Date:</td>
                                                            <th key={object.uuid + "th"} className="date-bold"
                                                            >{object.updatedAt && new Date(object.updatedAt).toISOString().replace(/.\d+Z$/g, "")}</th>
                                                        </tr>
                                                        <tr key={object.uuid + "sertr"}>
                                                            <td key={object.uuid + "sertd"}>Read?:</td>
                                                            <th key={object.uuid + "serth"}>
                                                                {/* <IonToggle disabled={true} checked={object?.IsSend}></IonToggle>  */}
                                                                {object?.IsSend ?
                                                                    <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={checkmarkCircle}

                                                                    />

                                                                    :
                                                                    <>
                                                                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#ff8800" }} icon={closeCircle}

                                                                        />
                                                                    </>
                                                                }
                                                            </th>
                                                        </tr>
                                                        {/* <tr key={object.uuid + "ptr"}>
                                                        <td key={object.uuid + "ptd"}>Subject:</td>
                                                        <th key={object.uuid + "pth"}>
                                                            <IonLabel class="ion-text-wrap notif-subject">
                                                                <div>{ReactHtmlParser(object?.Subject)}</div></IonLabel></th>
                                                    </tr> */}
                                                        <tr key={object.uuid + "str"}>
                                                            <td key={object.uuid + "std"}>Notif:</td>
                                                            <th key={object.uuid + "sth"}>
                                                                <div style={{ float: "left", width: "98%" }}>
                                                                    <IonLabel class="ion-text-wrap notif-content">
                                                                        {ReactHtmlParser(object?.Body)}

                                                                    </IonLabel>
                                                                </div>

                                                            </th>
                                                        </tr>
                                                    </table>


                                                </IonLabel>
                                            </IonCol>
                                            <IonCol size="1">
                                                {/* <IonLabel className="right-align" key={object.uuid+"deletel"}>
            <IonIcon key={object.uuid+"deletei"} onClick={ () => deletedevice(object?.Name,object?.SerialNumber)} className="top-actions-trash" slot="icon-only" icon={trashSharp} />
            </IonLabel>  */}   <div key={object.uuid + "content"}>
                                                    <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowForwardCircleOutline}
                                                        onClick={() => showmore(object?.Body)}
                                                    />
                                                </div>
                                            </IonCol>
                                        </IonRow>

                                    </IonItem>


                                </>;
                            })}




                        </IonList>


                    </IonCard>
                </IonContent >
            </IonPage >
            <IonModal isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Notification</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p>
                        {ReactHtmlParser(message)}

                    </p>
                </IonContent>
            </IonModal>
        </>
    );
}
export default Notification