import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { IonCard, IonCol, IonContent, IonGrid, IonLabel, IonList, IonRow } from "@ionic/react";
import moment from "moment-timezone";

export const DeviceLinearChart = (props: any) => {
  const { data, lastdata } = props;
  const [object, setobject] = useState(null);
  am4core.useTheme(am4themes_animated);


  function customizeGrip(grip: any) {
    // Remove default grip image
    grip.icon.disabled = true;

    // Disable background
    grip.background.disabled = true;

    // Add rotated rectangle as bi-di arrow
    var img = grip.createChild(am4core.Rectangle);
    img.width = 10;
    img.height = 10;
    img.fill = am4core.color("#999");
    img.rotation = 45;
    img.align = "center";
    img.valign = "middle";

    // Add vertical bar
    // var line = grip.createChild(am4core.Rectangle);
    // line.height = 2;
    // line.width = 3;
    // line.fill = am4core.color("#999");
    // line.align = "center";
    // line.valign = "middle";

  }

  useEffect(() => {

    setobject(lastdata)
  }, [lastdata])
  useEffect(() => {


    var chart = am4core.create("chartdiv", am4charts.XYChart);
    let chartdata = [{}];


    let counter = 0;
    if (data) {


      let datawithdate = [{}];
      datawithdate.pop()
      data.map(function (object: any, i: number) {

        if (object && object?.EpochTime) {
          object.EpochNumber = Number((object?.EpochTime.substring(0, 4) + object?.EpochTime.substring(7, 5) + object?.EpochTime.substring(10, 8) + object?.EpochTime.substring(13, 11) + object?.EpochTime.substring(16, 14) + object?.EpochTime.substring(19, 17)));
          datawithdate.push(object)
        }

        ///object.EpochNumber= Number(new Date(object?.EpochTime.substring(0,4), object?.EpochTime.substring(7,5), object?.EpochTime.substring(4,2), object?.EpochTime.substring(16,14), object?.EpochTime.substring(19,17)));
      })


      let sorteddata = datawithdate.sort(
        (objA: any, objB: any) => parseInt(objA.EpochNumber) - parseInt(objB.EpochNumber)

      );



      sorteddata = sorteddata.sort(

        (objA: any, objB: any) => parseInt(objA.EpochNumber) - parseInt(objB.EpochNumber)
      );
      sorteddata.map(function (object: any, i: number) {
        if (object && object?.EpochTime) {
          //let date = new Date(object?.EpochTime.substring(0, 4), object?.EpochTime.substring(7, 5), object?.EpochTime.substring(10, 8), object?.EpochTime.substring(13, 11), object?.EpochTime.substring(16, 14), object?.EpochTime.substring(19, 17))
          const __date = object?.EpochTime;//"Mon Jul 08 2019 06:05:22 GMT-0400 (Eastern Daylight Time)";
          const _date = moment.tz(__date, "Canada/Pacific").format();
          let date=new Date(_date)
          chartdata.push({ x2: date, y2: object?.TankPressure });
        }
        // }else{

        // }
        counter++;
      })
      counter = 0;



      sorteddata.map(function (object: any, i: number) {
        // if(counter<5){
        // chartdata.push({ x3:object?.EpochTime, y3: object?.TargetPressure });
        // chartdata.push({ x3:i + 3, y3: object?.TargetPressure });
        if (object && object?.EpochTime) {
          //let date = new Date(object?.EpochTime.substring(0, 4), object?.EpochTime.substring(7, 5), object?.EpochTime.substring(10, 8), object?.EpochTime.substring(13, 11), object?.EpochTime.substring(16, 14), object?.EpochTime.substring(19, 17))
          //  console.log(data)
          const __date = object?.EpochTime;//"Mon Jul 08 2019 06:05:22 GMT-0400 (Eastern Daylight Time)";
          const _date = moment.tz(__date, "Canada/Pacific").format();
          let date=new Date(_date)
          chartdata.push({ x3: date, y3: object?.TargetPressure });
        }
        //}
        counter++;
      })

      counter = 0;

      sorteddata.map(function (object: any, i: number) {
        // if(counter<5){

        // chartdata.push({ x1:i + 1, y1: object?.Temperature });
        if (object && object?.EpochTime) {
          //let date=new Date(2018, 3, 20, 10, 2+i)
         // let date = new Date(object?.EpochTime.substring(0, 4), object?.EpochTime.substring(7, 5), object?.EpochTime.substring(10, 8), object?.EpochTime.substring(13, 11), object?.EpochTime.substring(16, 14), object?.EpochTime.substring(19, 17))
         const __date = object?.EpochTime;//"Mon Jul 08 2019 06:05:22 GMT-0400 (Eastern Daylight Time)";
          const _date = moment.tz(__date, "Canada/Pacific").format();
          let date=new Date(_date)
          chartdata.push({ x1: date, y1: object?.Temperature });
          //chartdata.push({ x1:object?.EpochTime, y1: object?.Temperature });
          // }
        }
        counter++;
      })
      counter = 0;
      sorteddata.map(function (object: any, i: number) {
        //if(counter<5){
        // chartdata.push({ x4:i + 4, y4: object?.Volume });
        
        if (object && object?.EpochTime) {
          //let _date = new Date(object?.EpochTime.substring(0, 4), object?.EpochTime.substring(7, 5), object?.EpochTime.substring(10, 8), object?.EpochTime.substring(13, 11), object?.EpochTime.substring(16, 14), object?.EpochTime.substring(19, 17))
         // let _date = new Date(date.substring(0, 4), date.substring(7, 5), date.substring(10, 8), date.substring(13, 11), date.substring(16, 14), date.substring(19, 17))
         const __date = object?.EpochTime;//"Mon Jul 08 2019 06:05:22 GMT-0400 (Eastern Daylight Time)";
          const _date = moment.tz(__date, "Canada/Pacific").format();
          let date=new Date(_date)
          chartdata.push({ x4: date, y4: object?.Volume });
        }
        // }
        counter++;
      })
    }

    // console.log(chartdata)
    chart.data = chartdata;

    var xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.baseInterval = {
      "timeUnit": "minute",
      "count": 1
    };
    xAxis.renderer.labels.template.rotation = -60;
    xAxis.renderer.labels.template.horizontalCenter = "right";
    xAxis.renderer.labels.template.verticalCenter = "middle";
    xAxis.renderer.labels.template.fontSize = 8;
    //  xAxis.dateFormats.setKey("hour", "hh:mm a");
    xAxis.renderer.grid.template.location = 0;
    xAxis.strictMinMax = true;
    xAxis.renderer.minGridDistance = 30;
    xAxis.renderer.labels.template.fontSize = 13;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.fill = am4core.color("#00A6ED");
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.renderer.labels.template.fontSize = 12;

    var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.tooltip.disabled = true;
    valueAxis1.renderer.labels.template.fill = am4core.color("#FFB400");
    valueAxis1.renderer.minGridDistance = 30;
    valueAxis1.renderer.labels.template.fontSize = 10;

    var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.tooltip.disabled = true;
    //valueAxis2.strictMinMax = true;
    valueAxis2.renderer.labels.template.fill = am4core.color("#FF5300");
    valueAxis2.renderer.minGridDistance = 30;
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.labels.template.fontSize = 12;

    var series = chart.series.push(new am4charts.LineSeries());
    series.name = "Temperature";
    // series.dataFields.valueX = "x1";
    series.dataFields.dateX = "x1";
    series.dataFields.valueY = "y1";
    series.yAxis = valueAxis
    series.tooltipText = "{valueY.value}";
    series.fill = am4core.color("orange");
    series.stroke = am4core.color("orange");
    series.strokeWidth = 3;

    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = "Tank Pressure";
    //series2.dataFields.valueX = "x2";
    series2.dataFields.dateX = "x2";
    series2.dataFields.valueY = "y2";
    series2.yAxis = valueAxis1
    series2.tooltipText = "{valueY.value}";
    series2.fill = am4core.color("#c19e3a");
    series2.stroke = am4core.color("#c19e3a");
    series2.strokeWidth = 3;
    var series3 = chart.series.push(new am4charts.LineSeries());
    series3.name = "Target Pressure";
    //series3.dataFields.valueX = "x3";
    series3.dataFields.dateX = "x3";
    series3.dataFields.valueY = "y3";
    series3.yAxis = valueAxis1;
    series3.tooltipText = "{valueY.value}";
    series3.fill = am4core.color("#b0de09");
    series3.stroke = am4core.color("#b0de09");
    series3.strokeWidth = 3; 
    var series4 = chart.series.push(new am4charts.LineSeries());
    series4.name = "Volume";
    //series4.dataFields.valueX = "x4";
    series4.dataFields.dateX = "x4";
    series4.dataFields.valueY = "y4";
    series4.tooltipText = "{valueY.value}";
    series4.fill = am4core.color("#0080ff");
    series4.stroke = am4core.color("#0080ff");
    series4.strokeWidth = 3; 
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = xAxis;
    chart.cursor.yAxis = valueAxis;

    var scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    chart.scrollbarX.minHeight = 3;
    chart.scrollbarX.background.fill = am4core.color("#ACACAC");
    customizeGrip(chart.scrollbarX.startGrip);
    customizeGrip(chart.scrollbarX.endGrip);
    // chart.scrollbarX.thumb.height=0;

    chart.legend = new am4charts.Legend();
    chart.legend.zIndex = 100;
    chart.legend.position = "top"
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontSize = 8;
    chart.legend.maxHeight = 15;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 3;
    markerTemplate.height = 10;
  }, [data])

  return (
    <>
      <IonContent fullscreen className="content-zero-padding" >
        <IonGrid style={{ background: "white" }}>


          <IonRow>
            <IonCol size="12" style={{ padding: "0px" }}>
              <div className="linear-chart-container" id="chartdiv"></div>
            </IonCol>
          </IonRow>

          <IonRow >
            <IonCol size="12" style={{ overflow: "scroll" }}>
              <table className="info-table" >
                <tr>
                  <th>Time</th>
                  <th>Function</th>
                  <th>User Mode</th>
                  <th>Tank Pressure</th>
                  <th>Target Pressure</th>
                  <th>Volume</th>
                </tr>
                <tr>
                  <td>{object?.EpochTime && new Date(object?.EpochTime).toISOString().replace(/.\d+Z$/g, "")}</td>

                  <td>{object?.FunctionMsg}</td>
                  <td>{object?.UserModeMsg}</td>
                  <td>{object?.TankPressure}</td>
                  <td>{object?.TargetPressure}</td>
                  <td>{object?.Volume}</td>
                </tr>

              </table>


            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>



  )
}