import { createReducer } from "@reduxjs/toolkit";
import { setActiveTab } from "../actions/tabs";

const initialState = {
    selectedTab: 'devices'
};

export const ActiveTabReducer = createReducer(initialState, {
    [setActiveTab.type]: (state, action) => ({
        ...state,
        ...action.payload
    })
});