import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";

export const TemperatureChart = (props: any) => {
    am4core.useTheme(am4themes_animated);
    const {temperature} = props;
    const [tempraturef,settempraturef]=useState("");
   /* Create ranges */
function createRange(axis:any, from:any, to:any, color:any) {
    let range = axis.axisRanges.create();
    range.value = from;
    range.endValue = to;
    range.axisFill.fill = color;
    range.axisFill.fillOpacity = 0.8;
    range.label.disabled = true;
  }

  function lookUpGrade(lookupScore:any, grades:any) {
    // Only change code below this line
    for (var i = 0; i < grades.length; i++) {
      if (
        grades[i].lowScore < lookupScore &&
        grades[i].highScore >= lookupScore
      ) {
        return grades[i];
      }
    }
    return null;
  }
    useEffect(() => {
    
        let calcresult="";
        if(temperature ){
           calcresult=((temperature*9/5)+32).toFixed(2);
        }
        settempraturef(calcresult)
       
        am4core.useTheme(am4themes_animated);
        // Themes end
        
        let chart = am4core.create("chartdiv", am4charts.GaugeChart);
        chart.innerRadius = 80;//am4core.percent(90);
        am4core.useTheme(am4themes_animated);
       
        chart.paddingTop=0
        chart.startAngle=-180
        chart.endAngle=0
        //chart.innerRadius = -20;

        let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis2.min =-5;
        axis2.max = 20;
        axis2.strictMinMax = true;
        axis2.renderer.minGridDistance = 100;
        axis2.renderer.inside = true;
        axis2.renderer.radius = am4core.percent(97);

        axis2.renderer.line.strokeOpacity = 10;
        axis2.renderer.line.strokeWidth = 5;
        axis2.renderer.labels.template.radius = 35;
        
        axis2.renderer.ticks.template.length = 10;
        axis2.hiddenState.properties.opacity = 1;
        axis2.hiddenState.properties.visible = true;
        axis2.setStateOnChildren = true;
        axis2.renderer.hiddenState.properties.endAngle = 180;

        let range0 = axis2.axisRanges.create();
        range0.value = -5;
        range0.endValue = 5;
        range0.axisFill.fillOpacity = 10;
        range0.axisFill.fill = am4core.color('#11AE0C');
        range0.axisFill.zIndex = -1;

        let range1 = axis2.axisRanges.create();
        range1.value = 5;
        range1.endValue = 15;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color('#FFD500');
        range1.axisFill.zIndex = -1;

        let range2 = axis2.axisRanges.create();
        range2.value = 15;
        range2.endValue = 20;
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = am4core.color('#ff8800');
        range2.axisFill.zIndex = -1;

        let range3 = axis2.axisRanges.create();
        range3.value = 40;
        range3.endValue = 50;
        range3.axisFill.fillOpacity = 1;
        range3.axisFill.fill = am4core.color('orange');
        range3.axisFill.zIndex = -1;

        
        let hand = chart.hands.push(new am4charts.ClockHand());

        hand.fill = axis2.renderer.line.stroke;
        hand.stroke = axis2.renderer.line.stroke;
        hand.axis = axis2;
        hand.pin.radius = 8;
        hand.startWidth = 8;

        
        hand.value = temperature;

      
        new am4core.Animation(hand, {
            property: "value",
            from: 0,
            to: temperature
        }, 1000, am4core.ease.cubicOut).start();

        var legend = new am4charts.Legend();
        legend.markers.template.disabled = true;
        legend.isMeasured = false;
       legend.y = am4core.percent(80);
        legend.x=am4core.percent(0);
        legend.verticalCenter = "middle";
        legend.parent = chart.chartContainer;
        legend.data = [{
        "name": "°C",
        "fill": chart.colors.getIndex(0)
        }];


//var label = labelList.create();
var label=chart.radarContainer.createChild(am4core.Label);
//label.isMeasured = false;
label.fontSize = 25;
label.horizontalCenter = "middle";
// label.verticalCenter = "bottom";
label.parent = chart.chartContainer;
label.y = am4core.percent(55);
label.verticalCenter = "middle";
label.x = am4core.percent(50);
label.background.stroke = chart.colors.getIndex(0);
label.fill = chart.colors.getIndex(0);
label.text = temperature+"°C";
chart.events.on('sizechanged', () => {
    // label.scale = container.bbox.height / label.bbox.height;
    
    label.x = am4core.percent(50);
   });
 
var legend = new am4charts.Legend();
legend.markers.template.disabled = true;
legend.isMeasured = false;
legend.y = am4core.percent(100);
legend.verticalCenter = "bottom";
legend.parent = chart.chartContainer;

//         let chart = am4core.create("chartdiv", am4charts.XYChart);
// chart.paddingRight = 25;
// let temp=(parseFloat(temperature)+5);
// var obj={
//   "category": "Temperature C",
//   "value":temp ,

// };
// /* Add data */
// chart.data = [
//   obj

// ];

/* Create axes */
// let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
// categoryAxis.dataFields.category = "category";
// categoryAxis.renderer.minGridDistance = 30;
// categoryAxis.renderer.grid.template.disabled = true;

// let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
// valueAxis.renderer.minGridDistance = 30;
// valueAxis.renderer.grid.template.disabled = true;
// valueAxis.min = 0;
// valueAxis.max = 25;
// valueAxis.strictMinMax = true;
// valueAxis.renderer.labels.template.adapter.add("text", function(text) {
//   return (parseFloat(text)-5).toString() ;//+ "%";
// });



// createRange(valueAxis, 0, 10, am4core.color("#1e8ab1"));
// createRange(valueAxis, 10, 20, am4core.color("#ebeb86"));
// createRange(valueAxis, 20, 25, am4core.color("orange"));


/* Create series */
// let series = chart.series.push(new am4charts.ColumnSeries());
// series.dataFields.valueX = "value";
// series.dataFields.categoryY = "category";
// series.columns.template.fill = am4core.color("#000");
// series.columns.template.stroke = am4core.color("#fff");
// series.columns.template.strokeWidth = 1;
// series.columns.template.strokeOpacity = 0.5;
// series.columns.template.height = am4core.percent(25);



// var label=chart.createChild(am4core.Label);
// //label.isMeasured = false;
// label.fontSize = 25;
// label.horizontalCenter = "middle";
// // label.verticalCenter = "bottom";
// label.parent = chart.chartContainer;
// label.y = am4core.percent(55);
// label.verticalCenter = "middle";
// label.x = am4core.percent(50);
// label.background.stroke = chart.colors.getIndex(0);
// label.fill = chart.colors.getIndex(0);
// let tempf= temperature.toString();
// label.text =tempf;
// chart.events.on('sizechanged', () => {
//   // label.scale = container.bbox.height / label.bbox.height;
  
//   label.x = am4core.percent(50);
//  });
       
       

    }, [temperature])

    useEffect(() => {
if(tempraturef){

  let chartf = am4core.create("chartdivf", am4charts.GaugeChart);
  chartf.innerRadius = 80;//am4core.percent(90);
  am4core.useTheme(am4themes_animated);
 
  chartf.paddingTop=0
  chartf.startAngle=-180
  chartf.endAngle=0
  //chart.innerRadius = -20;

  let axis3 = chartf.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
  axis3.min =20;
  axis3.max = 70;
  axis3.strictMinMax = true;
  axis3.renderer.minGridDistance = 100;
  axis3.renderer.inside = true;
  axis3.renderer.radius = am4core.percent(97);

  axis3.renderer.line.strokeOpacity = 10;
  axis3.renderer.line.strokeWidth = 5;
  axis3.renderer.labels.template.radius = 35;
  
  axis3.renderer.ticks.template.length = 10;
  axis3.hiddenState.properties.opacity = 1;
  axis3.hiddenState.properties.visible = true;
  axis3.setStateOnChildren = true;
  axis3.renderer.hiddenState.properties.endAngle = 180;

  let range0 = axis3.axisRanges.create();
  range0.value = 20;
  range0.endValue = 40;
  range0.axisFill.fillOpacity = 10;
  range0.axisFill.fill = am4core.color('#1e8ab1');
  range0.axisFill.zIndex = -1;

  let range1 = axis3.axisRanges.create();
  range1.value = 40;
  range1.endValue = 60;
  range1.axisFill.fillOpacity = 1;
  range1.axisFill.fill = am4core.color('#FFD500');
  range1.axisFill.zIndex = -1;

  let range2 = axis3.axisRanges.create();
  range2.value = 60;
  range2.endValue = 70;
  range2.axisFill.fillOpacity = 1;
  range2.axisFill.fill = am4core.color('#ff8800');
  range2.axisFill.zIndex = -1;

 

  
  let hand2 = chartf.hands.push(new am4charts.ClockHand());

  hand2.fill = axis3.renderer.line.stroke;
  hand2.stroke = axis3.renderer.line.stroke;
  hand2.axis = axis3;
  hand2.pin.radius = 8;
  hand2.startWidth = 8;  
  hand2.value = tempraturef;

 
  new am4core.Animation(hand2, {
      property: "value",
      from: 20,
      to: parseInt(tempraturef)
  }, 1000, am4core.ease.cubicOut).start();

  var legend = new am4charts.Legend();
  legend.markers.template.disabled = true;
  legend.isMeasured = false;
  legend.y = am4core.percent(80);
  legend.x=am4core.percent(0);
  legend.verticalCenter = "middle";
  legend.parent = chartf.chartContainer;
  legend.data = [{
  "name": "F",
  "fill": chartf.colors.getIndex(0)
  }];


//var label = labelList.create();
var label=chartf.radarContainer.createChild(am4core.Label);
//label.isMeasured = false;
label.fontSize = 25;
label.horizontalCenter = "middle";
// label.verticalCenter = "bottom";
label.parent = chartf.chartContainer;
label.y = am4core.percent(55);
label.verticalCenter = "middle";
label.x = am4core.percent(50);
label.background.stroke = chartf.colors.getIndex(0);
label.fill = chartf.colors.getIndex(0);
label.text = tempraturef+" F";
chartf.events.on('sizechanged', () => {
// label.scale = container.bbox.height / label.bbox.height;

label.x = am4core.percent(50);
});

var legend = new am4charts.Legend();
legend.isMeasured = false;
legend.y = am4core.percent(100);
legend.verticalCenter = "bottom";
legend.parent = chartf.chartContainer;

  //let chartf = am4core.create("chartdivf", am4charts.XYChart);
  
  
  /* Add data */
  // chartf.data = [
  
  //  {
  //   "category": "Temperature F",
  //       "value": tempraturef,
   
  // }, 
 
  // ];
  
  // /* Create axes */
  // let categoryAxisf = chartf.yAxes.push(new am4charts.CategoryAxis());
  // categoryAxisf.dataFields.category = "category";
  // categoryAxisf.renderer.minGridDistance = 30;
  // categoryAxisf.renderer.grid.template.disabled = true;
  
  // let valueAxisf = chartf.xAxes.push(new am4charts.ValueAxis());
  // valueAxisf.renderer.minGridDistance = 30;
  // valueAxisf.renderer.grid.template.disabled = true;
  // valueAxisf.min = 20;
  // valueAxisf.max = 70;
  // valueAxisf.strictMinMax = true;
  // valueAxisf.renderer.labels.template.adapter.add("text", function(text) {
  //   return text ;//+ "%";
  // });
  
  
  
  // createRange(valueAxisf, 20,40, am4core.color("#1e8ab1"));
  // createRange(valueAxisf, 40, 60, am4core.color("#ebeb86"));
  // createRange(valueAxisf, 60, 70, am4core.color("orange"));
  // createRange(valueAxis, 60, 80, am4core.color("#f6d32b"));
  // createRange(valueAxis, 80, 100, am4core.color("#fb7116"));
  
  /* Create series */
  // let seriesf = chartf.series.push(new am4charts.ColumnSeries());
  // seriesf.dataFields.valueX = "value";
  // seriesf.dataFields.categoryY = "category";
  // seriesf.columns.template.fill = am4core.color("#000");
  // seriesf.columns.template.stroke = am4core.color("#fff");
  // seriesf.columns.template.strokeWidth = 1;
  // seriesf.columns.template.strokeOpacity = 0.5;
  // seriesf.columns.template.height = am4core.percent(25);
  
  
  
  
  // var labelf=chartf.createChild(am4core.Label);
  // //label.isMeasured = false;
  // labelf.fontSize = 25;
  // labelf.horizontalCenter = "middle";
  // // label.verticalCenter = "bottom";
  // labelf.parent = chartf.chartContainer;
  // labelf.y = am4core.percent(55);
  // labelf.verticalCenter = "middle";
  // labelf.x = am4core.percent(50);
  // labelf.background.stroke = chartf.colors.getIndex(0);
  // labelf.fill = chartf.colors.getIndex(0);
  // labelf.text = tempraturef.toString();

  // chartf.events.on('sizechanged', () => {
  //   // label.scale = container.bbox.height / label.bbox.height;
    
  //   labelf.x = am4core.percent(50);
  //  });
 
}
      
    },[tempraturef])
    return (
        <>

<IonRow>
          <IonCol size="6">
          <div className="chart-container" id="chartdiv"></div>
          </IonCol>
          <IonCol size="6">
          <div className="chart-container" id="chartdivf"></div>
          </IonCol>
        
        </IonRow>
        {/* <IonRow>
          <IonCol size="12">
          <div className="chart-container" id="chartdiv"></div>
          </IonCol>
        
        </IonRow>
        <IonRow>
        <IonCol size="12">
        <div className="chart-container" id="chartdivf"></div>
            </IonCol>
       
        </IonRow>  */}
        {/* <div className="chart-container" id="chartdiv"></div>
        <div className="chart-container" id="chartdivf"></div> */}
        </>
         
        // <Card title="Temperature (°C)" bodyStyle={{ paddingLeft: 0, paddingRight: '5px' }}>
        // <div style={{ position: 'relative' }}>
        //   <canvas className="chart-container" id="chartdiv"></canvas>
        // </div>
    //   </Card>
    )
}