import { IonCol, IonInput, IonLabel, IonRow, IonItem, IonIcon } from "@ionic/react";
import { helpCircle, search } from "ionicons/icons";
import { Controller, useFormContext } from "react-hook-form";


export const InputText = (props: any) => {
    const { name, label, rows, issearch, setisserach,defaultvalue, hasSearch,customclass, ...rest } = props
    
    const { control, formState: { errors } } = useFormContext();
    let namePath = name.replace(/\[(\w+)\]/g, '.$1') //items[1] => items.1
    

    const searchfunc = async () => {
        
        if(issearch!=null){
            setisserach(!issearch)
        }
       
        };
       
    //simple name : "title" 
    //path name : "items[1].title"
    // let namePath = name.replace(/\[(\w+)\]/g, '.$1') //items[1] => items.1
    // let error = objectPath.get(errors, namePath);
    //  let hasError = !!error;
    // let value = values ? objectPath.get(values, namePath) : null;
    return (<>
        {/* <IonCol size="6">
            <IonLabel position="fixed">{label}</IonLabel>
        </IonCol> */}
        <IonCol size="12">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                    <>
                    {/* <IonItem outline="true">
  <IonLabel position="stacked">Stacked Label</IonLabel>
  <IonInput></IonInput>
</IonItem> */}
                    {/* <OutlinedInput
                        type="text"
                        label={label}
                        multiline={Boolean(rows)}
                        rows={rows || 0}
                        error={errors[name]} /> */}
                    <IonItem className={"border-zero custom-input  "+customclass}   >
                      <IonLabel class="ion-text-wrap" position="floating" >{label}</IonLabel>
                    <IonInput
                    onIonChange={onChange}
                    value={value}
                    defaultValue={defaultvalue}
                    ref={ref}
                    className={errors[name] ? "input-err" : ""}
                    ></IonInput>
                    {/* <input
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        className={errors[name] && "input-err"}
                    /> */}
                   {hasSearch && 
                   <>
                    <button type="button" slot="end" className="input-button" onClick={searchfunc}> <IonIcon className="input-icon"  icon={search}></IonIcon></button>
                   </>
                   }
                   
                    </IonItem>
                    {/* <input
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        className={errors[name] && "input-err"}
                    /> */}
                    </>
        
                )}
                {...rest}
            />
             {errors[name] && <p className=" box err-msg">{errors[name].message}</p>}
        </IonCol>
    </>);
}