import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form"
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";
import fractionUnicode from 'fraction-unicode';
import { arrowBackCircle } from "ionicons/icons";
import { Link } from "react-router-dom";

const CO2FlowRate = () => {
    const formMethod = useForm();

    const [cfhmaxresult, setcfhmaxresult] = useState("");
    const [bblresult, setbblresult] = useState("");
    const [cfvolume, setcfvolume] = useState("");
    const [cfh, setcfh] = useState("");
    const [cfhratio, setcfhratio] = useState("");
    const [prescrub, setprescrub] = useState("");
    const [prescrubratio, setprescrubratio] = useState("");
    const [flowmeter, setflowmeter] = useState("");
    const [gravitylist, setgravitylist] = useState([{}]);
    const [requiredvolumlist, setrequiredvolumlist] = useState([{}]);
    const [britetanklist, setbritetanklist] = useState([{}]);

    const length = formMethod.watch("Length", null)
    const height = formMethod.watch("Height", null)
    const gravity = formMethod.watch("Gravity", null)
    const Stoneporosity = formMethod.watch("Stoneporosity", null)

    const Requiredvolumes = formMethod.watch("Requiredvolumes", null)
    const Britetankvolume = formMethod.watch("Britetankvolume", null)
    const Approx = formMethod.watch("Approx", null)


    useEffect(() => {

        let rows = [{}]
        for (let i = 0; i <= 32; i++) {
            let count = 0.996 + i * 0.002
            rows.push({ value: count, text: count });
        }
        setgravitylist(rows)
        rows = [{}]
        for (let i = 0; i <= 42; i++) {
            let count = 1.9 + i * 0.05
            rows.push({ value: count, text: count });
        }
        setrequiredvolumlist(rows)
        rows = [{}]
        for (let i = 0; i <= 58; i++) {
            let count = 5 + i * 2.5
            rows.push({ value: count, text: count });
        }
        setbritetanklist(rows)
    }, [])
    useEffect(() => {
        let calcresult = "";
        let prescrubc = "";
        if (length && height && gravity && Stoneporosity) {
            let porositycoef = 0;
            let gravitycoef = 0;
            let lengthcoef = 0;
            switch (Stoneporosity) {
                case 1:
                    porositycoef = 0.5
                    break
                case 2:
                    porositycoef = 0.7
                    break
                case 3:
                    porositycoef = 0.95
                    break
            }
            switch (parseFloat(gravity)) {
                case 0.996:
                    gravitycoef = 1
                    break
                case 0.998:
                    gravitycoef = 1
                    break
                case 1:
                    gravitycoef = 1
                    break
                case 1.002:
                    gravitycoef = 0.97
                    break
                case 1.004:
                    gravitycoef = 0.94
                    break
                case 1.006:
                    gravitycoef = 0.91
                    break
                case 1.008:
                    gravitycoef = 0.88
                    break
                case 1.010:
                    gravitycoef = 0.85
                    break
                case 1.012:
                    gravitycoef = 0.78
                    break
                case 1.014:
                    gravitycoef = 0.71
                    break
                case 1.016:
                    gravitycoef = 0.62
                    break
                case 1.018:
                    gravitycoef = 0.6
                    break
                case 1.020:
                    gravitycoef = 0.59
                    break
                case 1.022:
                    gravitycoef = 0.58
                    break
                case 1.024:
                    gravitycoef = 0.57
                    break
                case 1.026:
                    gravitycoef = 0.56
                    break

                case 1.028:
                    gravitycoef = 0.55
                    break
                case 1.030:
                    gravitycoef = 0.54
                    break
                case 1.032:
                    gravitycoef = 0.53
                    break
                case 1.034:
                    gravitycoef = 0.52
                    break
                case 1.036:
                    gravitycoef = 0.51
                    break
                case 1.038:
                    gravitycoef = 0.50
                    break
                case 1.040:
                    gravitycoef = 0.49
                    break
                case 1.042:
                    gravitycoef = 0.48
                    break
                case 1.044:
                    gravitycoef = 0.47
                    break
                case 1.046:
                    gravitycoef = 0.46
                    break
                case 1.048:
                    gravitycoef = 0.45
                    break
                case 1.050:
                    gravitycoef = 0.44
                    break
                case 1.052:
                    gravitycoef = 0.43
                    break
                case 1.054:
                    gravitycoef = 0.42
                    break
                case 1.056:
                    gravitycoef = 0.41
                    break
                case 1.058:
                    gravitycoef = 0.40
                    break
                case 1.060:
                    gravitycoef = 0.39
                    break

            }

            switch (parseFloat(length)) {
                case 4:
                    // lengthcoef=4.2
                    
                    lengthcoef = -0.0000004 * height * 4 + 0.00005 * height * 3 - 0.0019 * height * 2 + 0.0728 * height - 0.0019;
                    
                    break
                case 6:
                    // lengthcoef=4.2
                    lengthcoef = -0.0000005 * height * 4 + 0.00006 * height * 3 - 0.0024 * height * 2 + 0.1124 * height - 0.1447;
                    break
                case 8:
                    lengthcoef = -0.0000006 * height * 4 + 0.00008 * height * 3 - 0.0031 * height * 2 + 0.148 * height - 0.1826
                    break
                case 10:
                    lengthcoef = -0.000001 * height * 4 + 0.0001 * height * 3 - 0.0062 * height * 2 + 0.211 * height - 0.2939
                    break
                case 12:
                    lengthcoef = -0.0000009 * height * 4 + 0.0001 * height * 3 - 0.0047 * height * 2 + 0.2246 * height - 0.2943
                    break
                case 14:
                    lengthcoef = -0.000001 * height * 4 + 0.0001 * height * 3 - 0.0055 * height * 2 + 0.2622 * height - 0.3446
                    break
                case 16:
                    lengthcoef = -0.000001 * height * 4 + 0.0002 * height * 3 - 0.0063 * height * 2 + 0.2997 * height - 0.3938
                    break
                case 18:
                    lengthcoef = -0.000001 * height * 4 + 0.0002 * height * 3 - 0.0068 * height * 2 + 0.335 * height - 0.4481
                    break
                case 20:
                    lengthcoef = -0.000002 * height * 4 + 0.0002 * height * 3 - 0.0078 * height * 2 + 0.3736 * height - 0.4941
                    break
                case 22:
                    lengthcoef = -0.000002 * height * 4 + 0.0002 * height * 3 - 0.0087 * height * 2 + 0.4121 * height - 0.5415
                    break
                case 24:
                    lengthcoef = -0.000002 * height * 4 + 0.0003 * height * 3 - 0.0107 * height * 2 + 0.457 * height - 0.5775
                    break
                case 26:
                    lengthcoef = -0.000002 * height * 4 + 0.0003 * height * 3 - 0.0103 * height * 2 + 0.487 * height - 0.64
                    break
                case 28:
                    lengthcoef = -0.000002 * height * 4 + 0.0003 * height * 3 - 0.0112 * height * 2 + 0.5247 * height - 0.6846
                    break
                case 30:
                    lengthcoef = -0.000002 * height * 4 + 0.0003 * height * 3 - 0.0112 * height * 2 + 0.5619 * height - 0.7326
                    break
                case 32:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0127 * height * 2 + 0.599 * height - 0.7807
                    break
                case 34:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0135 * height * 2 + 0.636 * height - 0.8288
                    break
                case 36:
                    
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0143 * height * 2 + 0.6732 * height - 0.8786
                    break
                case 38:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.015 * height * 2 + 0.7103 * height - 0.9249
                    break
                case 40:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0158 * height * 2 + 0.7475 * height - 0.973
                    break
                case 42:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0166 * height * 2 + 0.7846 * height - 1.021
                    break
                case 44:
                    lengthcoef = -0.000003 * height * 4 + 0.0003 * height * 3 - 0.0173 * height * 2 + 0.8217 * height - 1.0691
                    break
                case 46:
                    lengthcoef = -0.000004 * height * 4 + 0.0003 * height * 3 - 0.0181 * height * 2 + 0.8588 * height - 1.1172
                    break
                case 48:
                    debugger
                    lengthcoef = -0.000004 * height * 4 + 0.0003 * height * 3 - 0.0189 * height * 2 + 0.8959 * height - 1.1652
                    break

            }
            //debugger
            calcresult = (porositycoef * gravitycoef * lengthcoef).toFixed(2);

        }
        setcfhmaxresult(calcresult);


    }, [length, height, gravity, Stoneporosity])


    useEffect(() => {

        let calcresult = "";
        let calcnumber = 0;
        //debugger
        if (Britetankvolume) {
            calcnumber = (4.1 * Britetankvolume);
            calcresult = calcnumber.toFixed(2)
            setbblresult(calcresult)
            if (Requiredvolumes) {
                calcnumber = calcnumber * Requiredvolumes;
                calcresult = calcnumber.toFixed(2);
                setcfvolume(calcresult)

                calcnumber = (14 * calcnumber) / 44;
                calcresult = calcnumber.toFixed(2);
                setflowmeter(calcresult);

                if (Approx && Approx > 0) {

                    let ratio = Britetankvolume / Approx;

                    if (cfhmaxresult && parseFloat(cfhmaxresult) > 0) {
                        let h = calcnumber;
                        calcnumber = (h / parseFloat(cfhmaxresult)) * ratio;
                        calcresult = calcnumber.toFixed(2);
                        let prescrubc = (calcnumber * 1.35).toFixed(2);
                        var s = prescrubc.split('.');
                        if (s.length > 0) {
                            setprescrub(s[0])
                            if (s.length > 1) {
                                //prescrubratio!=null && prescrubratio!=null ? :""

                                setprescrubratio(fractionUnicode(parseInt(s[1]), Math.pow(10, s[1].length)))
                            } else {
                                setprescrubratio("")
                            }
                        }
                        var ss = calcresult.split('.');
                        if (ss.length > 0) {
                            setcfh(ss[0])
                            if (ss.length > 1) {
                                // setprescrubratio(s[1])
                                setcfhratio(fractionUnicode(parseInt(ss[1]), 10));
                            } else {
                                setcfhratio("")
                            }
                        }

                        //  setcfh(calcresult);
                    }

                }
            }

        }


    }, [cfhmaxresult, Requiredvolumes, Britetankvolume, Approx])
    const onSubmitHandler = (data: any) => {
        // debugger
    }
    return (
        <IonPage>
            {/* <IonHeader  style={{ display: "flex" }}>
            <IonToolbar>
                <div  >

                    <Link to="/calculation">
                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                    </Link>
                </div>
                <div style={{ flex: "1" }}>
                Co2 Stone flow rate calculator
                </div>
                </IonToolbar>
            </IonHeader> */}
            <IonHeader>
                <IonToolbar style={{ display: "flex" }}>
                    <div style={{ float: "left" }}>
                        <Link to="/calculation">
                            <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                        </Link>
                    </div>
                    <div style={{ flex: "1" }}>
                        <IonTitle>Co2 Stone flow rate calculator</IonTitle>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList className="no-padding" >
                    <FormProvider {...formMethod}>
                        <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{ background: "white" }} >
                            <div className="list-subheader">Please specify below parameters(Type of beverage is Beer):</div>
                            <IonItem>
                                {/* <InputText label="Carbonation stone length(inch):" name="Length" /> */}
                                <InputActionSheet label="Carbonation stone length(inch):" header="Carbonation stone length(inch):" name="Length"
                                    options={[
                                        { value: 4, text: '4' },
                                        { value: 6, text: '6' },
                                        { value: 8, text: '8' },
                                        { value: 10, text: '10' },
                                        { value: 12, text: '12' },
                                        { value: 14, text: '14' },
                                        { value: 16, text: '16' },
                                        { value: 18, text: '18' },
                                        { value: 20, text: '20' },
                                        { value: 22, text: '22' },
                                        { value: 24, text: '24' },
                                        { value: 26, text: '26' },
                                        { value: 28, text: '28' },
                                        { value: 30, text: '30' },
                                        { value: 32, text: '32' },
                                        { value: 34, text: '34' },
                                        { value: 36, text: '36' },
                                        { value: 38, text: '38' },
                                        { value: 40, text: '40' },
                                        { value: 42, text: '42' },
                                        { value: 44, text: '44' },
                                        { value: 46, text: '46' },
                                        { value: 48, text: '48' },
                                    ]} />
                            </IonItem>
                            <IonItem>
                                <InputActionSheet label="Stone porosity Microns:" header="Stone porosity Microns:" name="Stoneporosity"
                                    options={[{ value: 1, text: 'Poor= 2 to 5 Micron' },
                                    { value: 2, text: 'Good= 0.6 to 1 Micron' },
                                    { value: 3, text: 'Best= 0.5 to 0.2 Micron' }]} />
                            </IonItem>
                            <IonItem>
                                {/* <InputText label="Height of product over stone(inch):" name="Height" /> */}

                                <InputActionSheet class="ion-text-wrap" label="Height of product over stone(inch):" header="Height of product over stone(inch):" name="Height"
                                    options={[{ value: 5, text: '5' },
                                    { value: 10, text: '10' },
                                    { value: 15, text: '15' },
                                    { value: 20, text: '20' },
                                    { value: 25, text: '25' },
                                    { value: 30, text: '30' },
                                    { value: 35, text: '35' },
                                    { value: 40, text: '40' },
                                    { value: 45, text: '45' },
                                    { value: 50, text: '50' },
                                    { value: 55, text: '55' },
                                    { value: 60, text: '60' },
                                    { value: 65, text: '65' },
                                    ]} />
                            </IonItem>
                            <IonItem>
                                {/* <InputText label="Finish Gravity:" name="Gravity" /> */}
                                <InputActionSheet label="Finish Gravity:" header="Finish Gravity:" name="Gravity"
                                    options={gravitylist}

                                />
                            </IonItem>
                            {/* <div className="list-subheader no-padding-bottom">Result:</div> */}
                            <div className="calc-result-box" >
                                <IonItem className="withoutborder whiteclass" style={{ color: "white" }}>
                                    <IonCol size="12">
                                        Result
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">Recommended Flow rate in CFH MAX:</IonLabel>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">{cfhmaxresult}</IonLabel>
                                    </IonCol>
                                </IonItem>
                            </div>
                        </form>
                    </FormProvider>
                </IonList>
                <Devider />
                <IonList className="no-padding" >
                    <FormProvider {...formMethod}>
                        <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{ background: "white" }}>
                            <div className="list-subheader">Parameters:</div>
                            <IonItem>
                                {/* <InputText label="Required volumes:" name="Requiredvolumes" /> */}
                                <InputActionSheet label="Required volumes:" header="Required volumes:" name="Requiredvolumes"
                                    options={requiredvolumlist}

                                />
                            </IonItem>
                            <IonItem>
                                {/* <InputText label="Brite tank volume BBL:" name="Britetankvolume" /> */}
                                <InputActionSheet label="Brite tank volume BBL:" header="Brite tank volume BBL:" name="Britetankvolume"
                                    options={britetanklist}

                                />
                            </IonItem>
                            <IonItem>
                                {/* <InputText label="Approx amount of product BBL:" name="Approx" /> */}
                                <InputActionSheet label="Approx amount of product BBL:" header="Approx amount of product BBL:" name="Approx"
                                    options={britetanklist}

                                />
                            </IonItem>
                            <div className="calc-result-box">
                                <IonItem className="withoutborder whiteclass" style={{ color: "white" }} >
                                    <IonCol size="12">
                                        Result
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">BBL into CF product:</IonLabel>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">{bblresult}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">CF product x volumes:</IonLabel>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">{cfvolume}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">Correction for pressure in flow meter:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap">{flowmeter}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap">Volume CF</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">CF volume / CFH:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap">{cfh} {cfhratio}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap">Hours</IonLabel>
                                    </IonCol>
                                </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed" class="ion-text-wrap">Add pre scrub:</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap">{prescrub}  {prescrubratio}</IonLabel>
                                    </IonCol>
                                    <IonCol size="3">
                                        <IonLabel position="fixed" class="ion-text-wrap"></IonLabel>
                                    </IonCol>
                                </IonItem>
                            </div>
                        </form>
                    </FormProvider>
                </IonList>
            </IonContent>
        </IonPage>
    )
}
export default CO2FlowRate