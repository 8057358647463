import React from 'react'
import { FirebaseProvider } from '@useweb/firebase/useFirebase'
import { initializeApp } from 'firebase/app'
import { getMessaging, isSupported,getToken } from 'firebase/messaging'
import { registerServiceWorker } from './register-sw';
import { DeviceToken } from './services/device.service';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZ0FD85-1gQ9dN0Xy6Wgwtz0Zc0yQgMlc",
  authDomain: "fizzwizz-72a09.firebaseapp.com",
  projectId: "fizzwizz-72a09",
  storageBucket: "fizzwizz-72a09.appspot.com",
  messagingSenderId: "452419606295",
  appId: "1:452419606295:web:0ceedc15cff74643158952",
  measurementId: "G-W8J7K9DZ01"
};

const firebaseApp = initializeApp(firebaseConfig)
// Messaging is not supported in ios or safari as of 2022
const messagingIsSupported =  isSupported()
const messaging = messagingIsSupported ? getMessaging(firebaseApp) : undefined

const envIsDev = process.env.NODE_ENV === 'development'

const vapidKey = "BH1iaOBGeUKb0-zt3XXc2gv0v2PV3GsHrKjBHdqaZ92kurpy8i6r_85dR-LDFFJWlwJs-7Iqm0wLW6fHA1sziiY" // vapidKey is required

export  function Firebase () {
  //registerServiceWorker();
//const Firebase: React.FC<{children:any }> = (props) => {
  return (
    <FirebaseProvider
      firebaseConfig={firebaseConfig}
      firebaseApp={firebaseApp}
      envIsDev={envIsDev}
      messaging={messaging}
      messagingOptions={{
        vapidKey,
      }}
    >
      {/* {children} */}
    </FirebaseProvider>
  )
}



getToken(messaging, { vapidKey: 'BH1iaOBGeUKb0-zt3XXc2gv0v2PV3GsHrKjBHdqaZ92kurpy8i6r_85dR-LDFFJWlwJs-7Iqm0wLW6fHA1sziiY' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
    console.log(currentToken)
    localStorage.setItem('FCMToken', currentToken);
    
    DeviceToken({});
   // alert(currentToken)
  } else {
    // Show permission request UI
    
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

//export default Firebase