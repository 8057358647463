import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form"
import { Link } from "react-router-dom";
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";

const ABVBasic = () => {

    const[result,setresult]=useState("");
    const formMethod = useForm();
    const[gravitylist,setgravitylist]=useState([{}]);
    const originalgravity = formMethod.watch("OriginalGravity", null)
    const finalgravity = formMethod.watch("FinalGravity", null)
    useEffect(() => {
        let rows=[{}]
        for (let i = 0; i <= 42; i++) {
            let count=0.996+i*0.002
            rows.push({ value: count, text: count });
          }
          setgravitylist(rows)
         
    },[])
    useEffect(() => {
        let calcresult="";
        
        if(finalgravity && originalgravity){
           calcresult=((originalgravity-finalgravity)*131.25).toFixed(2);
        }
        setresult(calcresult);
       
      }, [originalgravity,finalgravity])
    const onSubmitHandler = (data: any) => {
       // debugger
    }
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar  style={{ display: "flex" }}>
                <div style={{float:"left"}}>
                    <Link to="/calculation">
                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                    </Link>
                    </div>
                <div style={{ flex: "1" }}>
                                      <IonTitle>Carbonation level checker</IonTitle>
                                      </div>
                </IonToolbar>
                
            </IonHeader>
            <IonContent fullscreen >
                <div className="flex-content">
                    <IonList className="no-padding" >
                        <FormProvider {...formMethod}>
                            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{background: "white"}}>
                                <div className="list-subheader">Please specify below parameters(Type of beverage is Beer):</div>
                                {/* <IonItem> */}
                                <h4 style={{color:"#1e8ab1"}}>Alcohol By Volume Simple Method</h4>
                                {/* </IonItem> */}
                               
                                <IonItem>
                                    {/* <InputText label="Original Gravity:" name="OriginalGravity" /> */}
                                    <InputActionSheet label="Original Gravity:" header="Original Gravity:" name="OriginalGravity"
                                     options={gravitylist}
                                     
                                        />
                                </IonItem>
                                <IonItem>
                                    {/* <InputText label="Final Gravity:" name="FinalGravity" /> */}
                                    <InputActionSheet label="Final Gravity:" header="Final Gravity:" name="FinalGravity"
                                     options={gravitylist}
                                     
                                        />
                                </IonItem>
                                <div className="calc-result-box">
                                <IonItem className="withoutborder whiteclass"  style={{color:"white"}} >
                                    <IonCol size="12">
                                        Result
                                    </IonCol>
                                    </IonItem>
                                <IonItem className="white-border" >
                                    <IonCol size="6">
                                        <IonLabel position="fixed">ABV(%)</IonLabel>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonLabel position="fixed">{result}</IonLabel>
                                    </IonCol>
                                </IonItem>
                                </div>
                            </form>
                        </FormProvider>
                    </IonList>
                    <div>
                        <Devider />
                        <div className="formula-container">
                            <img src="/assets/images/ABVCalc.png" />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
export default ABVBasic