import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";
// import { getPlatforms,isPlatform  } from '@ionic/react';

export const TankPressurreChart = (props: any) => {
    am4core.useTheme(am4themes_animated);
    const { tankPressure } = props;

    const [showbadge, setshowbadge] = useState(true)

    // const changebadge = () => {
        
    //     //var oriention = window.screen.orientation;
    //     if (window && window?.screen && window?.screen?.width && window.screen.width <600) {
    //         setshowbadge(true)
    //     }else{
    //         setshowbadge(false)
    //     }
    // }

    useEffect(()=>{
        // changebadge()
        // if (window && window?.screen && window?.screen?.width ) {
        // window.screen.orientation.onchange = function (e) {

        //     changebadge()
        // }
   // }
    },[])
    useEffect(() => {


        // var platform=getPlatforms();
        // debugger
        //const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);

        let chart = am4core.create("chartdiv", am4charts.GaugeChart);
        chart.innerRadius = -20;//am4core.percent(90);
        am4core.useTheme(am4themes_animated);
        // chart.marginRight =10
        // chart.marginLeft = 10
        // chart.paddingLeft = 10
        // chart.paddingRight = 10
        chart.paddingTop = 0
        chart.startAngle = -260
        chart.endAngle = 80
        //chart.innerRadius = -20;

        let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
        axis2.min = 0;
        axis2.max = 50;
        axis2.strictMinMax = true;
        axis2.renderer.minGridDistance = 100;
        axis2.renderer.inside = true;
        axis2.renderer.radius = am4core.percent(97);
        //axis2.renderer.radius = 80;
        axis2.renderer.line.strokeOpacity = 10;
        axis2.renderer.line.strokeWidth = 5;
        axis2.renderer.labels.template.radius = 35;
        //axis2.renderer.ticks.template.strokeOpacity = 1;
        //axis2.renderer.grid.template.disabled = true;
        axis2.renderer.ticks.template.length = 10;
        axis2.hiddenState.properties.opacity = 1;
        axis2.hiddenState.properties.visible = true;
        axis2.setStateOnChildren = true;
        axis2.renderer.hiddenState.properties.endAngle = 180;

        let range0 = axis2.axisRanges.create();
        range0.value = 0;
        range0.endValue = 50;
        range0.axisFill.fillOpacity = 1;
        range0.axisFill.fill = am4core.color('#BBBABA');

        let range1 = axis2.axisRanges.create();
        range1.value = 0;
        range1.endValue = 50;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color('#E5E5E5');

        // let label = chart.radarContainer.createChild(am4core.Label);
        // label.isMeasured = false;
        // label.fontSize = 25;
        // label.horizontalCenter = "middle";
        // label.verticalCenter = "bottom";
        let hand = chart.hands.push(new am4charts.ClockHand());
        //     hand.axis = axis2;
        //     hand.innerRadius=40
        //     hand.pin.disabled=true
        //    // hand.disabled=true;
        //     hand.startWidth = 0;
        hand.fill = axis2.renderer.line.stroke;
        hand.stroke = axis2.renderer.line.stroke;
        hand.axis = axis2;
        hand.pin.radius = 8;
        hand.startWidth = 8;

        hand.value = tankPressure;

        // hand.events.on("propertychanged", function (ev) {
        //     range0.endValue = ev.target.value;
        //     range1.value = ev.target.value;
        //     label.text = axis2.positionToValue(hand.currentPosition).toFixed(0);
        //     axis2.invalidate();
        // });
        new am4core.Animation(hand, {
            property: "value",
            from: 0,
            to: tankPressure
        }, 1000, am4core.ease.cubicOut).start();

        var legend = new am4charts.Legend();

        legend.markers.template.disabled = true;
        legend.isMeasured = false;
        legend.y = am4core.percent(60);
        legend.x = am4core.percent(0);
        legend.verticalCenter = "middle";
        legend.parent = chart.chartContainer;
        legend.useDefaultMarker = true;
        //      let marker = legend.markers.template.children.getIndex(0);
        // //marker.cornerRadius(12, 12, 12, 12);
        //         marker.strokeWidth = 2;
        //         marker.strokeOpacity = 1;
        //         marker.stroke = am4core.color("white");
        legend.data = [{
            "name": "PSI",
            "fill": chart.colors.getIndex(0),
            "strokeOpacity": 0
        }];
        chart.events.on('sizechanged', () => {
            // label.scale = container.bbox.height / label.bbox.height;

            legend.y = am4core.percent(70 + container.bbox.height);
        });
        const container = chart.createChild(am4core.Container);
        container.horizontalCenter = 'middle';
        container.verticalCenter = 'bottom';
        container.height = am4core.percent(20);
        container.x = am4core.percent(50);
        container.y = am4core.percent(100);
        container.isMeasured = false;

        var label=chart.radarContainer.createChild(am4core.Label);
        var label = container.createChild(am4core.Label);
        //label.isMeasured = false;
        label.fontSize = 20;
        label.padding(4, 8, 4, 8);

        // label.verticalCenter = "bottom";
        label.parent = chart.chartContainer;
if(showbadge){



        label.text = tankPressure;


        label.fontSize = 20;
        label.horizontalCenter = "middle";

        label.parent = chart.chartContainer;
        label.y = am4core.percent(55);
        label.verticalCenter = "middle";
        label.x = am4core.percent(50);
        label.background.stroke = chart.colors.getIndex(0);
        label.fill = chart.colors.getIndex(0);

        chart.events.on('sizechanged', () => {
           // label.scale = container.bbox.height / label.bbox.height;

            label.x = am4core.percent(50 + container.bbox.width);
        });
    }
        var legend = new am4charts.Legend();
        legend.isMeasured = false;
        legend.y = am4core.percent(100);
        legend.verticalCenter = "bottom";
        legend.parent = chart.chartContainer;

    }, [tankPressure,showbadge])
    return (
        <>
            <div style={{ background: "white" }}>
                <IonRow>
                    <IonCol size="1">


                        <img src="/assets/images/Arm.png" style={{ minWidth: "35px" }} />
                        <img src="/assets/images/Ston.png" style={{ minWidth: "35px" }} />
                    </IonCol>
                    <IonCol size={!showbadge ? "7":"9"}>
                    {!showbadge  ?
                        <div className="chart-container" id="chartdiv" style={{float:"right"}}></div>
                        :
                        <div className="chart-container" id="chartdiv" ></div>
                        }


                    </IonCol>
                   {!showbadge &&
                        <IonCol size="3">
                        <IonItem className="custom-border" >
                            <IonCol size="6">
                                <IonLabel position="fixed" class="ion-text-wrap" className="c-value">{tankPressure}</IonLabel>
                            </IonCol>
                            <IonCol size="6">
                                <IonLabel position="fixed" class="ion-text-wrap" className="chart-icon">PSI</IonLabel>
                            </IonCol>
                        </IonItem>
                       
                    </IonCol>
                    }
                    
                </IonRow>
            </div>

        </>

    )
}