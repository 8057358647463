import axios from 'axios';
import Toastr from '../shared/Toastr';
// import { actionTypes } from '../store/ducks/auth.duck';
// import { loaderActions } from '../store/ducks/loader.duck';
// import { snackbarActions } from '../store/ducks/snackbar.duck';

class BaseService {

    baseUrl:any = null;
    store:any = null; // may came from parent project

    // baseUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? // dev code
    //     this._baseUrllocal : this._baseUrlserver

    // constructor() {
    //     let service = axios.create();
    //     this.service = service;
    // }

    init = (store:any, url:any) => {
        if (url)
            this.baseUrl = url;
        if (store)
            this.store = store;
    }

    baseFileUrl = this.baseUrl + 'api/file/getFile/';

    // constructor() {
    //     let service = axios.create();
    //     this.service = service;
    // }


    handleError = (error:any) => {
        
        // this.store.dispatch(loaderActions.hide());
        // this.store.dispatch(snackbarActions.error("خطا در اتصال به سرور "));
        console.log(error);
        if (error.response) {
            switch (error.response.status) {
                // case 401:
                //     this.store.dispatch(snackbarActions.error("لطفا مجدد وارد شوید"));
                //     this.store.dispatch(actionTypes.logout());
                //     break;
                // case 403:
                //     this.store.dispatch(snackbarActions.error("دسترسی ندارید"));
                //     break;
                case 404:
                    console.log(error);
                    break;
                default:
                    //this.redirectTo(document, '/500')
                    console.log(error);
                    // Toastr.error(error);
                    break;
            }
        }

        return Promise.reject(error.response || error.message);
    }

    redirectTo = (document:any, path:any) => {
        document.location = path
    }

    // get(path, callback) {
    //     return this.service.get(path).then(
    //         (response) => callback(response.status, response.data)
    //     );
    // }

    postFile(formData:any, progressHandler:any) {
        return axios.request({
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            url: this.baseUrl + 'api/file/upload',
            responseType: 'json',
            data: formData,
            onUploadProgress: progressHandler
        }).then(response => { return response.data })
            .catch(this.handleError);
    }
    postWithUrl(path:any, payload:any) {
        const { auth: { token } } = this.store.getState();

        return axios.request({
            headers: { authorization: `Bearer ${token}` },
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        }).then(response => { return response.data })
            .catch(this.handleError);
    }
    post(path:any, payload:any) {
        
        const authToken  = this.store.getState().auth?.token;

        path = this.baseUrl + 'api' + path;
        return axios.request({
            headers: { authorization: `Bearer ${authToken}` },
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        }).then(response => { return response.data })
            .catch(this.handleError);
    }
    get(path:any, payload:any) {
        
        const authToken  = this.store.getState().auth?.token;

        path = this.baseUrl + 'api' + path;
        return axios.request({
            headers: { authorization: `Bearer ${authToken}` },
            method: 'Get',
            url: path,
            responseType: 'json',
            data: payload
        }).then(response => { return response.data })
            .catch(this.handleError);
    }

    getwitherror(path:any, payload:any) {
        
        const authToken  = this.store.getState().auth?.token;

        path = this.baseUrl + 'api' + path;
        return axios.request({
            headers: { authorization: `Bearer ${authToken}` },
            method: 'Get',
            url: path,
            responseType: 'json',
            data: payload
        }).then(response => { return response.data })
            .catch(response => { return response });
    }
}


export default new BaseService;
