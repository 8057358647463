import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { addCircle, helpCircle } from "ionicons/icons";

const DeviceDrawing = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/devices" />
                    </IonButtons>
                    <div className="drawing-title"><IonIcon className="top-actions" slot="icon-only" icon={helpCircle} /> <br /> Connection Drawing</div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="drawing-container">
                    <img src="/assets/images/connectionDrawing.PNG" />
                </div>
            </IonContent>
        </IonPage>
    )
}
export default DeviceDrawing;