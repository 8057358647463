import { StepLineSeriesDataItem } from "@amcharts/amcharts4/charts";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonItem, IonList, IonPage, IonTitle, IonToolbar, IonButton, IonGrid, IonCol, IonToast, IonIcon } from "@ionic/react";
import { arrowBackCircle } from "ionicons/icons";
import {   useEffect, useRef, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Snackbar from "react-native-snackbar";
// import Snackbar from "react-native-snackbar";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { InputText } from "../../editors/InputText";
import { snackbarActions } from "../../redux/snackbar.redux";
import { create, Search } from "../../services/device.service";
//import { Device } from '@awesome-cordova-plugins/device/ngx';
// import DeviceInfo from 'react-native-device-info';
//import { Device } from '@capacitor/device';
//import { App } from '@capacitor/app';
//import { UniqueDeviceID } from '@ionic-native/unique-device-id'

// import { getUniqueId, getManufacturer } from 'react-native-device-info';


const AddDevice = () => {
    
    const [step, setStep] = useState(1);
    const [data, setdata] = useState(null);
    const[showToast,setShowToast]=useState(false);
    const[showerrorToast,setshowerrorToast]=useState(false);
    //constructor(props) { }
    // const logDeviceInfo = async () => {
    //     const info = await Device.getInfo();
    //     debugger
    //     const uid = await UniqueDeviceID.get()
    //     alert (uid)
    //     console.log(info);
        
    //     //alert(info)
    //     };
   //debugger
//    useEffect(() => {
//     let info =  App.getInfo() // error
    
  
     
//     // (async () => {
//     //     //debugger
//     //     let info = await App.getInfo() // error
//     //     alert (info)
     
//     // })() 
//     //logDeviceInfo()

    
//   }, [])
  
   
   

// var i=  DeviceInfo.getUniqueId();
// console.log(i);
//.then(uniqueId => {
//     console.log(uniqueId)
//     // iOS: "FCDBD8EF-62FC-4ECB-B2F5-92C9E79AC7F9"
//     // Android: "dd96dec43fb81c97"
//   });
    //var device: Device;
  //  console.log('Device UUID is: ' + device.uuid);
    return (
        <>
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div style={{    float: "left"}}>
                <Link to="/devices">
              <IonIcon className="top-actions-add" slot="icon-only" style={{color:"#1e8ab1"}} icon={arrowBackCircle} />
            </Link> 
            </div>
                    <div className="center">
                   
                        Register New Device
                        
                        </div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {step == 1 && <RegisterDevice 
                  onCancel={(item:boolean) => (
                    <>
                    {item==true && setShowToast(true)}
                    { item==false && setshowerrorToast(true)
                    
                }
                    {/* { 
                    //setStep(1)
                    } */}
                    </>
                    
                     )} 
                // onRegister={() => 
                // setStep(2)
                // }
                 setdata={setdata} />}
                {/* {step == 2 && <DeviceInfo1 onCancel={(item:boolean) => (
                    <>
                    {item && setShowToast(true)
                    
                    }
                    { setStep(1)}
                    </>
                    
                     )} device={data} />} */}
            </IonContent>
        </IonPage>
        <IonToast
         isOpen={showToast}
         onDidDismiss={() => setShowToast(false)}
         message="Your Changes have been saved."
         duration={200}
         color="success"
         />
          <IonToast
         isOpen={showerrorToast}
         onDidDismiss={() => setshowerrorToast(false)}
         message="Your Information is not Correct. Device was not found?"
         duration={200}
         color="danger"
         />
         </>
    )
}
export default AddDevice;

export const RegisterDevice = (props: any) => {
    const dispatch = useDispatch();
    const {onRegister, setdata, onCancel } = props;
    const formMethod = useForm();
    const[isconfirm,setisconfirm]=useState(false);
    const[SubscriptionName,setSubscriptionName]=useState("");
    // useEffect(() => {
    //     //debugger
    //     //formMethod.reset()
            
    //       }, [isconfirm])
    const onSubmitHandler = (data: any) => {
        
       // onRegister();
        setdata(data)
       
        Search(data.SubscriptionName,data.SerialNumber,{}).then(res => {
           // //setShowToast(true)
           // debugger
            if(res.length>0){
                let devicelist=[];
                var d=localStorage.getItem('CurrentDevices');
                if(d && d!=null && d!='undefined'){
                let currentdevices=JSON.parse(d);
                
                if(currentdevices==null || currentdevices.length==0){
                  devicelist.push(data)
               
                localStorage.setItem('CurrentDevices', JSON.stringify(devicelist));
        
        
                }else{
                    currentdevices.push(data);
                localStorage.setItem('CurrentDevices', JSON.stringify(currentdevices));
        
                }
                }else{
                    devicelist.push(data)
                    localStorage.setItem('CurrentDevices', JSON.stringify(devicelist));
                }
                //setShowToast(true)
               // formMethod.reset({SubscriptionName:"",SerialNumber:""})
              // dispatch(snackbarActions.success("Successfully Saved"))
                onCancel(true)
                //setisconfirm(!isconfirm)
                setSubscriptionName("")
                onCancel(true)
            }else{
                onCancel(false)
            }
                
        
         });
       
       // return true;
      
    }
    return (
        <>
        <FormProvider {...formMethod}>
            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container">
                <IonList>
                    
                        <InputText label="Device Name:" name="SubscriptionName" value={SubscriptionName} rules={{ required: "Device Name is required" }} />
                    
                    
                        <InputText label="Device Serial Number:" name="SerialNumber" rules={{ required: "Device Serial Number is required" }} />
                    
                </IonList>
                <div className="form-actions" >
                    <IonButton color="primary" class="blue-default"  type="submit">Register</IonButton>
                </div>
            </form>
        </FormProvider>
        
         </>
    )
}


export const DeviceInfo1 = (props: any) => {
    const { onCancel, device } = props;
    const formMethod = useForm();
    const dispatch = useDispatch();
    const[showToast,setShowToast]=useState(false);
    
    //const [showToast, setShowToast] = useState(false)
    const onSubmitHandler = (data: any) => {
       
        data.SubscriptionName = device.SubscriptionName;
        data.SerialNumber = device.SerialNumber;

        create(data).then(res => {
           //setShowToast(true)
           
               onCancel(true)
        //    Snackbar.show({
        //     text: 'Hello world',
        //     duration: Snackbar.LENGTH_SHORT,
        //   });
        // toast({
        //     message: 'Message sent'
        // });
                   
        //     dispatch(snackbarActions.success("Successfully Saved"))
        
            
            // toast({
            //     message: 'Message sent',
            //     color: 'success'
            // });

        });
    }
    return (
        <>
        <FormProvider {...formMethod}>
            <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container">
                <IonList>
                    <IonItem>
                        <InputText label="Device Name:" name="Name" rules={{ required: "Device Name is required" }} />
                    </IonItem>
                    <IonItem>
                        <InputText label="Serial Number:" name="SerialNumber" rules={{ required: "Serial Number is required" }} />
                    </IonItem>
                    <IonItem>
                        <InputText label="Product Type:" name="ProductType" rules={{ required: "Product Type is required" }} />
                    </IonItem>
                    {/* <IonItem>
                        <InputText label="Device IP:" name="DeviceIP" rules={{ required: "Device IP is required" }} />
                    </IonItem> */}
                </IonList>
                <div className="form-actions">
                    <IonButton color="primary" type="submit">Confirm</IonButton>
                    <IonButton color="danger" onClick={onCancel}>Cancel</IonButton>
                </div>
            </form>
        </FormProvider>

        <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Your Changes have been saved."
        duration={200}
        />
        </>
    )
}