import { Redirect, Route } from "react-router";

import Devices from "./Devices";
import Home from "./Home";
import Calculation from "./Calculation";
import Disclaimer from "./Disclaimer";
import DeviceDrawing from "../components/devices/DeviceDrawing";
import AddDevice from "../components/devices/AddDevice";
import CO2FlowRate from "../components/calculation/CO2FlowRate";
import CarbonationLevel from "../components/calculation/CarbonationLevel";
import BottleConditioning from "../components/calculation/BottleConditioning";
import ABVBasic from "../components/calculation/ABVBasic";
import CarbonationWatch from "../components/calculation/CarbonationWatch";
import Notification from "../components/home/Notification";
import IBUTinseth from "../components/calculation/IBUTinseth";
import Login from "./login";


const PageRoute = () => {
return (<>
    <Route exact path="/devices">
           <Devices />
    </Route>
    <Route exact path="/home">
        <Home />
    </Route>
    <Route exact path="/devicehome/:deviceId">
        <Home />
    </Route>
    <Route path="/calculation">
        <Calculation />
    </Route>
    <Route path="/disclaimer">
        <Disclaimer />
    </Route>
    <Route exact path="/deviceDrawing">
        <DeviceDrawing />
    </Route>
    <Route exact path="/addDevice">
        <AddDevice />
    </Route>
    <Route exact path="/co2FlowRate">
        <CO2FlowRate />
    </Route>
    <Route exact path="/carbonationLevel">
        <CarbonationLevel />
    </Route>
    <Route exact path="/bottleConditioning">
        <BottleConditioning />
    </Route>
    <Route exact path="/abvBasic">
        <ABVBasic />
    </Route>
    <Route exact path="/carbonationWatch">
        <CarbonationWatch />
    </Route>
    <Route exact path="/DeviceNotification/:serialnumber">
        <Notification />
    </Route>
    <Route exact path="/notification">
        <Notification />
    </Route>
    <Route exact path="/iBUTinseth">
        <IBUTinseth />
    </Route>
    <Route exact path="/">
        <Redirect to="/devices" />
    </Route>
    <Route exact path="/login">
        <Login/>
    </Route>
</>)
}
export default PageRoute;
