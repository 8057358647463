import { IonCol, IonInput, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { Controller, useFormContext } from "react-hook-form";


export const InputActionSheet = (props: any) => {
    const { name,header,options, label, rows, ...rest } = props
    const { control, formState: { errors } } = useFormContext();
    const customActionSheetOptions = {
        header: header
    };
    let htmlOptions:any=[];
    for (var i = 0; i < options.length; i++) {
        htmlOptions.push(<IonSelectOption value={options[i].value}>{options[i].text}</IonSelectOption>);
    }
    return (<>
        <IonCol size="6">
            <IonLabel class="ion-text-wrap" position="fixed">{label}</IonLabel>
        </IonCol>
        <IonCol size="6">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                    <IonSelect
                        // interfaceOptions={customActionSheetOptions}
                        // interface="action-sheet"
                        // placeholder="Select One"
                        onIonChange={onChange}
                        value={value}
                        ref={ref}
                    >
                        {htmlOptions}
                    </IonSelect>
                )}
                {...rest}
            />
            {errors[name] && <p className="err-msg">{errors[name].message}</p>}
        </IonCol>
    </>);
}