import { IonAvatar, IonBadge, IonCard, IonCol, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRow, useIonAlert } from "@ionic/react";
import { addCircle, trashSharp, logOut, removeCircle, trailSignOutline, notificationsCircle, notifications } from "ionicons/icons";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "../theme/devices.css"
import { List, LocalList } from "../services/device.service";
import store, { useAppDispatch } from "../redux/store";
import rootReducer from "../redux/reducers";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { strictEqual } from "assert";
import { string } from "@amcharts/amcharts4/core";
import { logoutAction } from "../redux/reducers/auth";
import { InputText } from "../editors/InputText";
import { FormProvider, useForm } from "react-hook-form";


const Tab1 = () => {
  const dispatch = useAppDispatch()
  const [devicelist, setdevicelist] = useState([]);
  const [issearch, setisserach] = useState(false);
  const [totaldevicelist, settotaldevicelist] = useState([]);
  const formMethod = useForm();
  const devicesearch = formMethod.watch("DeviceSearch", null)
  const [presentAlert] = useIonAlert();
  const [roleMessage, setRoleMessage] = useState('');

  const deletedevice = (name: any, SerialNumber: any) => {

    presentAlert({
      header: 'Are You Sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            // setHandlerMessage('Alert confirmed');

            let currentdevices = JSON.parse(localStorage.getItem('CurrentDevices'));

            if (currentdevices == null || currentdevices.length == 0) {


            } else {

              if (currentdevices) {
                const filtered = currentdevices.filter((item: any) => {
                  return item.SerialNumber !== SerialNumber.toString();
                });
                setdevicelist(devicelist.filter(x => x.SerialNumber?.toString().indexOf(SerialNumber.toString()) == -1));

                localStorage.setItem('CurrentDevices', JSON.stringify(filtered));
              }



            }
          },
        },
      ],
      onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
    })


  }
  const search = () => {

    if (devicesearch) {

      setdevicelist(devicelist.filter(x => x.SerialNumber?.toString().indexOf(devicesearch) > -1 || x.Name.indexOf(devicesearch) > -1));

    } else {
      setdevicelist(totaldevicelist)
    }
  }
  useEffect(() => {

    search()
  }, [devicesearch, issearch])


  useEffect(() => {

    LocalList({ req: null }).then(res => {
      
      setdevicelist(res);
      settotaldevicelist(res)

    });
  }, [])

  return (
    <IonPage>

      <IonContent fullscreen>
        <FormProvider {...formMethod}>
          <form className="form-container">
            <IonRow class="row-center">
              <IonCol size="11">
                {/* <IonItem> */}
                <InputText label="Search Device Name or Serial Number:" name="DeviceSearch" issearch={issearch} setisserach={setisserach} hasSearch={true} />
                {/* </IonItem> */}

              </IonCol>
            </IonRow>

          </form></FormProvider>
        <IonCard>
          <IonList>
            <IonListHeader style={{ display: "flex" }}>
              <div style={{ flex: "1" }}>
                Device
              </div>
              <div  >
                <Link to="/addDevice">
                  <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={addCircle} />
                </Link>
              </div>

            </IonListHeader>
            {devicelist && devicelist.length > 0 && devicelist.map(function (object, i) {
              return <>

                <IonItem class="device-list" key={object.uuid}>
                  {/* <div style={{background:"white",padding: "10px 97px;",width:"100%", borderRadius: "2%;"}}> */}
                  <IonRow>

                    <IonCol size="2" className="">
                    <Link to={"/devicehome/"+object.uuid}>
                      <div className="circle-item">

                        <img src="/assets/images/Device-01.png" />
                      </div>
                      {/* </IonAvatar> */}
                      </Link>
                    </IonCol>
                    <IonCol size="8">
                   
                      <IonLabel key={object.uuid + "label"}>
                        <table className="simple-table" key={object.uuid + "table"}>
                          <tr key={object.uuid + "tr"}>
                            <td key={object.uuid + "td"}>Device Name:</td>
                            <th key={object.uuid + "th"}>{object.Name}</th>
                          </tr>
                          <tr key={object.uuid + "sertr"}>
                            <td key={object.uuid + "sertd"}>SerialNumber:</td>
                            <th key={object.uuid + "serth"}>{object.SerialNumber}</th>
                          </tr>
                          <tr key={object.uuid + "ptr"}>
                            <td key={object.uuid + "ptd"}>Product Type:</td>
                            <th key={object.uuid + "pth"}>{object.ProductType}</th>
                          </tr>
                          <tr key={object.uuid + "str"}>
                            <td key={object.uuid + "std"}>Status:</td>
                            <th key={object.uuid + "sth"}>{object.Status}</th>
                          </tr>
                        </table>


                      </IonLabel>
                     
                    </IonCol>
                    <IonCol size="1">
                      <IonLabel className="right-align" key={object.uuid + "notif"}>
                      <Link to={"/DeviceNotification/"+object.SerialNumber}>
                          {object?.NotifCount>0 ?
                         <>
                         <IonBadge className="notif" slot="start">{object?.NotifCount}</IonBadge>
                         <IonIcon className="top-actions-trash" slot="icon-only" style={{color:"#FFB265"}} icon={notifications} />
                         </>
                          
                           :
                          <IonIcon className="top-actions-trash" slot="icon-only" icon={notifications} />
                          }
                          
                        </Link>
                      </IonLabel>
                      {/* <IonLabel className="right-align" key={object.uuid + "deletel"}>
                        <IonIcon key={object.uuid + "deletei"} onClick={() => deletedevice(object?.Name, object?.SerialNumber)} className="top-actions-trash" slot="icon-only" icon={trashSharp} />
                      </IonLabel> */}
                    </IonCol>
                    <IonCol size="1">
                    
                      <IonLabel className="right-align" key={object.uuid + "deletel"}>
                        <IonIcon key={object.uuid + "deletei"} onClick={() => deletedevice(object?.Name, object?.SerialNumber)} className="top-actions-trash" slot="icon-only" icon={trashSharp} />
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                </IonItem>


              </>;
            })}




          </IonList>


        </IonCard>
      </IonContent >
    </IonPage >
  );
};

export default Tab1;
