import { configureStore } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {  createStore } from "redux";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}


const expireReducer = require("redux-persist-expire");



const persistConfig = {
  key: 'root',
  storage: storage,
 // timeout: 1000,
  transforms: [expireReducer(rootReducer, {
    expireSeconds: 3600
 })],
 // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
 };
 
 const pReducer = persistReducer(persistConfig, rootReducer);
 const store = createStore(pReducer);

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

//export default store;
export default store;
export const persistor = persistStore(store);



