import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./auth";
import { ActiveTabReducer } from "./tabs";

const appReducer = combineReducers({
    /* your app’s top-level reducers */
    activeTab: ActiveTabReducer,
    auth: authReducer
})

//   const rootReducer = (state, action,activeTab,auth) => {
//     return appReducer(state, action,ActiveTabReducer,authReducer)
//   }
const rootReducer = (state: any, action: any) => {
    
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined,  { type: undefined })
    }

    return appReducer(state, action)
}

// const rootReducer = combineReducers({
//     activeTab: ActiveTabReducer,
//     auth: authReducer
// });
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

