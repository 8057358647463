
import { string } from '@amcharts/amcharts4/core';
import baseService from './base.service'


export function List(req: any) {
  return baseService.get("/device/deviceAll", req);
}

export function LocalList(req: any) {

  //let result=null;

  let deviceserialnumbers = "";
  var d = localStorage.getItem('CurrentDevices');
  if (d && d != null && d != 'undefined') {
    let currentdevices = JSON.parse(d);
    let counter = 0;
    if (currentdevices) {
      currentdevices.map(function (item: any, i: number) {
        if (counter == 0) {
          deviceserialnumbers = (item.SerialNumber);

        } else {
          deviceserialnumbers = deviceserialnumbers + "&" + (item.SerialNumber);

        }
        counter++;
      })

    }
  }
  return baseService.get("/device/deviceLocalList/" + deviceserialnumbers, req);
  // return null;
  //Promise.reject('_currentInstruction is fale');

}


export function NotifList(req: any, currentserialnumber: string) {


  let deviceserialnumbers = "";
  let token = (localStorage.getItem('FCMToken'));
  if (currentserialnumber != null) {
    deviceserialnumbers = currentserialnumber;
  } else {
    
    let currentdevices = JSON.parse(localStorage.getItem('CurrentDevices'));
    let counter = 0;
    if (currentdevices) {
      currentdevices.map(function (item: any, i: number) {
        if (counter == 0) {
          deviceserialnumbers = (item.SerialNumber);

        } else {
          deviceserialnumbers = deviceserialnumbers + "&" + (item.SerialNumber);

        }
        counter++;
      })

    }
  }

  return baseService.get("/device/NotifList/" + token + "/" + deviceserialnumbers, req);

}

export function GetFCMNotif(req: any) {

  let deviceserialnumbers = "";
  let token = (localStorage.getItem('FCMToken'));
  return baseService.get("/device/GetFCMNotif/" + token, req);

}

export function DeviceToken(req: any) {

  //let result=null;
  let deviceserialnumbers = "";
  let token = (localStorage.getItem('FCMToken'));
  let currentdevices = JSON.parse(localStorage.getItem('CurrentDevices'));
  let counter = 0;
  if (currentdevices) {
    currentdevices.map(function (item: any, i: number) {
      if (counter == 0) {
        deviceserialnumbers = (item.SerialNumber);

      } else {
        deviceserialnumbers = deviceserialnumbers + "&" + (item.SerialNumber);

      }
      counter++;
    })

  }
  return baseService.get("/device/saveDevices/" + deviceserialnumbers + "/" + token, req);
  // return null;
  //Promise.reject('_currentInstruction is fale');

}
export function create(data: any) {
  return baseService.post("/device", data);
}
export function getdeviceteamid(deviceid: string, req: any) {
  return baseService.get("/teams/" + deviceid, req);
}
export function Search(devicename: string, SerialNumber: string, req: any) {
  return baseService.get("/device/search/" + devicename + "/" + SerialNumber, req);
}