import { RadarChart } from '@amcharts/amcharts4/charts';
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOption, IonLabel, IonList, IonPage, IonRouterOutlet, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { notificationsCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Link, Route, useParams } from 'react-router-dom';
import { DeviceNote } from '../components/home/DeviceNote';
import { CO2Chart } from '../components/home/CO2Chart';
import "../theme/home.css"
import { TemperatureChart } from '../components/home/TemperatureChart';
import { TankPressurreChart } from '../components/home/TankPressurreChart';
import { DeviceLinearChart } from '../components/home/DeviceLinearChart';
import { getdeviceteamid, List, LocalList } from '../services/device.service';
import { BatchList, TelemetryList } from '../services/batch.service';
import { FormProvider, useForm } from 'react-hook-form';
import { act } from 'react-dom/test-utils';
import { InputActionSheet } from '../editors/InputActionSheet';
import Toastr from '../shared/Toastr';
import { debug } from 'console';

const Tab2 = () => {

  const { deviceId } = useParams<{ deviceId: string }>();
  const [tab, selectedTab] = useState('tankPressure');
  const [devicelist, setdevicelist] = useState([]);
  const [currentdevice, setcurrentdevice] = useState(null);
  const [selectedDevice, setselectedDevice] = useState(null);
  const [selectedbatchId, setselectedbatchId] = useState(null);
  const [batchlist, setbatchlist] = useState([]);

  const [activebatch, setactivebatch] = useState(null);
  const [telemetrydata, settelemetrydata] = useState(null);
  const [telemetryalldata, settelemetryalldata] = useState(null);
  const formMethod = useForm();
  const [showToast, setShowToast] = useState(false)
  const [errormessage, seterrormessage] = useState("")


  // const selectedDevice = formMethod.watch("SelectedDevice", "")
  //  debugger
  //  window.screen.orientation.lock('landscape');

  useEffect(() => {
    LocalList({ req: null })
      .then(res => {
        setdevicelist(res);

        if (deviceId) {
          setcurrentdevice(devicelist.filter(x => x.uuid == deviceId))

          setselectedDevice(deviceId)

        } else {
          setcurrentdevice(res[0])
          setselectedDevice(res[0].uuid)
        }



      });


  }, [])


  // const [position, setPosition] = useState(window.pageYOffset)
  // const [visible, setVisible] = useState(true) 
  // useEffect(()=> {
  //     const handleScroll = () => {
  //        let moving = window.pageYOffset
  //        debugger
  //        setVisible(position > moving);
  //        setPosition(moving)
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return(() => {
  //        window.removeEventListener("scroll", handleScroll);
  //     })
  // })

  //const cls = visible ? "visible" : "hidden";

  const telemetrydatagetitem = (batchid: any, deviceid: any) => {
    // debugger
    try {
      settelemetrydata([])
      settelemetryalldata([])
      TelemetryList({ req: null }, batchid, deviceid).then(res => {
           
        if (res && res.last && res.all) {
          
          settelemetrydata(res.last);
          settelemetryalldata(res.all);
        } else {
          if (res.code == "ERR_BAD_REQUEST" && res.response && res.response.data && res.response.data.message) {

            seterrormessage(res.response.data.message)
            setShowToast(true)

          }

        }

      });
    } catch (error) {

    }

  }
  useEffect(() => {

    if (currentdevice?.uuid) {
      // getdeviceteamid(currentdevice?.uuid ,{req: null }).
      // then(teamid=>{
      //   if(teamid){
        
      settelemetrydata([])
      settelemetryalldata([])

      BatchList({ req: null }, currentdevice?.uuid).then(res => {
        setbatchlist(res);

        if (res && res.length > 0) {
          // let rows = [{}]

          // res.map(function (object: any, i: number) {
          //   rows.push({ value: object.uuid, text: object.Name });
          // })

          // setbatchdropdownlist(rows)

          let batch = res[0];
          setactivebatch(res[0])
          setselectedbatchId(res[0]?.uuid)

          console.log(selectedbatchId)
          //formMethod.setValue("SelectedBatch",activebatch);
          if (batch) {

            telemetrydatagetitem(batch.uuid, currentdevice?.uuid);

            //   TelemetryList({req: null},batch.uuid,currentdevice?.uuid).then(res => {

            //     settelemetrydata(res.last);
            //     settelemetryalldata(res.all);
            //  });
          }

        }

      });
      //   }

      // });

    }

  }, [currentdevice])

  useEffect(() => {

    if (selectedDevice) {

      //setcurrentdevice(selectedDevice)
      setcurrentdevice(devicelist.filter(x => x.uuid == selectedDevice)[0]);
    }



  }, [selectedDevice])

  useEffect(() => {

    if (currentdevice) {

      telemetrydatagetitem(activebatch.uuid ?? activebatch.value, currentdevice?.uuid);
    }



  }, [activebatch])

  const onSubmitHandler = (data: any) => {
    // debugger
  }

  return (
    <IonPage>
      <IonHeader collapse='fade' className={`well`}>
        {/* <Toastr showToast={showToast}></Toastr> */}
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={errormessage}
          duration={200}
          color="danger"
        />
        <IonToolbar className='toolbar-custom' >
          <IonTitle size="large" style={{ height: "100%", position: "relative", padding: "0" }}>
            <FormProvider {...formMethod}>
              <form onSubmit={formMethod.handleSubmit(onSubmitHandler)} className="form-container" style={{ background: "white" }} >
                <IonRow className='horizontal-half-left'>
                  <IonCol size='2'>
                    <IonLabel className='blue-label'>Device: </IonLabel>
                  </IonCol>
                  <IonCol size='10'>

                    <IonSelect
                      onIonChange={(e) => {
                        //console.log(e);

                        setselectedDevice(e.detail.value)
                      }}
                      placeholder="Select Device" name="SelectedDevice" value={selectedDevice} >

                      {devicelist && devicelist.length > 0 && devicelist.map(function (object, i) {
                        return <>

                          <IonSelectOption value={object.uuid}>{object.Name} - {object.SerialNumber}</IonSelectOption>


                        </>;
                      })}


                    </IonSelect>
                  </IonCol>
                </IonRow>
                <IonRow className='horizontal-half'>
                  <IonCol size='2'>
                    <IonLabel className='blue-label'>Batch: </IonLabel>
                  </IonCol>
                  <IonCol size='10' className='padding-right-20' >


                    <IonSelect
                      onIonChange={(e) => {
                        //console.log(e);

                        setselectedbatchId(e.detail.value)
                        setactivebatch(e.detail)
                      }}
                      placeholder="Select Batch" name="SelectedBatch" value={selectedbatchId} >

                      {batchlist && batchlist.length > 0 && batchlist.map(function (object, i) {
                        return <>

                          <IonSelectOption value={object.uuid}>{object.Name}</IonSelectOption>


                        </>;
                      })}
                    </IonSelect>
                  </IonCol>
                </IonRow>

              </form>
            </FormProvider>

          </IonTitle>
          <IonButtons slot="end">
            <Link to="/notification">
              <IonIcon className="top-actions-add" slot="icon-only" icon={notificationsCircle} />
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid className='padding-zero' >

          {/* <IonRow > */}
          {/* <IonCol size="2">
              <span>
                Device:
              </span>


            </IonCol>
            <IonCol size="4">
              <table>
                <tr><td>{currentdevice?.Name}</td></tr>
                <tr><td>{currentdevice?.SerialNumber}</td></tr>
                <tr><td>{currentdevice?.ProductType}</td></tr>
              </table>
            </IonCol> */}
          {/* <IonCol size="2">
              <span>
                Batch:
              </span>

            </IonCol> */}
          {/* <IonCol size="4" class='limit-col'>


              <table>
                {batchlist && batchlist.length > 0 && batchlist.map(function (object, i) {
                  return <>
                    <tr>

                      <td>

                        <IonItemOption style={{ color: "black" }} className='link-button' color="primary" > {object.Name}</IonItemOption>

                      </td>
                    </tr>

                  </>;
                })

                }
               
              </table>

            </IonCol> */}
          {/* </IonRow> */}
          <IonSegment className='home-segment' value={tab} onIonChange={(e: any) => selectedTab(e.detail.value)} color="success" mode="ios">
            <IonSegmentButton value="tankPressure">
              <IonLabel class="ion-text-wrap">Tank Pressure</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="co2">
              <IonLabel class="ion-text-wrap">CO2</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="temperature">
              <IonLabel class="ion-text-wrap">Temperature</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="statistics">
              <IonLabel class="ion-text-wrap">Statistics</IonLabel>
            </IonSegmentButton>

          </IonSegment>

          <IonRow>
            {tab == 'co2' && <>
              <IonCol>
                <CO2Chart co2value={telemetrydata?.Co2Level} deviceid={currentdevice?.uuid} />
              </IonCol>
              {/* <IonCol><DeviceNote /></IonCol> */}
            </>}
            {tab == 'temperature' && <>
              <IonCol>
                <TemperatureChart temperature={telemetrydata?.Temperature ?? ""} />
              </IonCol>
              {/* <IonCol><DeviceNote /></IonCol> */}
            </>}
            {tab == 'statistics' && <>
              <IonCol>
                <DeviceLinearChart data={telemetryalldata} lastdata={telemetrydata} />
              </IonCol>
            </>}
            {tab == 'tankPressure' && <>
              <IonCol>
                <TankPressurreChart tankPressure={telemetrydata?.TankPressure} />
              </IonCol>
              {/* <IonCol><DeviceNote /></IonCol> */}
            </>}
          </IonRow>
          {tab != 'statistics' && <>
            <IonRow style={{ background: "white" }}>
              <IonCol size="12" style={{ overflow: "scroll" }}>
                <table className="info-table" >
                  <tr>
                    <th>Time</th>
                    <th>Function</th>
                    <th>User Mode</th>
                    <th>Tank Pressure</th>
                    <th>Target Pressure</th>
                    <th>Volume</th>
                  </tr>
                  <tr>
                    <td>{telemetrydata?.EpochTime && new Date(telemetrydata?.EpochTime).toISOString().replace(/.\d+Z$/g, "")}</td>
                    <td>{telemetrydata?.FunctionMsg}</td>
                    <td>{telemetrydata?.UserModeMsg}</td>
                    <td>{telemetrydata?.TankPressure}</td>
                    <td>{telemetrydata?.TargetPressure}</td>
                    <td>{telemetrydata?.Volume}</td>
                  </tr>

                </table>


              </IonCol>
            </IonRow>
          </>}
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Tab2;
