import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBackCircle } from "ionicons/icons";
import { FormProvider, useForm } from "react-hook-form"
import { Link } from "react-router-dom";
import { InputActionSheet } from "../../editors/InputActionSheet";
import { InputText } from "../../editors/InputText";
import { Devider } from "../../shared/GeneralComponents";

const CarbonationWatch = () => {
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar  style={{ display: "flex" }}>
                <div style={{float:"left"}}>
                    <Link to="/calculation">
                        <IonIcon className="top-actions-add" slot="icon-only" style={{ color: "#1e8ab1" }} icon={arrowBackCircle} />
                    </Link>
                    </div>
                <div style={{ flex: "1" }}>
                                      <IonTitle>Carbonation Watch and Learn</IonTitle>
                                      </div>
                </IonToolbar>
               
            </IonHeader>
            <IonContent fullscreen >
            <IonCard>
                    <div className="carbonation-watch">
                        <Link to={{ pathname: 'https://www.brewwizz.com/pages/watch-learn/' }} target="_blank"><h3>Watch & Learn! - BrewWizz</h3></Link>
                        <IonLabel>Training videos</IonLabel>
                        {/* <p>See how your data should look, and match your charts with ours.</p> */}
                        <Link to={{ pathname: 'https://www.brewwizz.com/collections/all' }} target="_blank"><h3>Products - BrewWizz</h3></Link>
                        <IonLabel>Products and Parts</IonLabel>
                    </div>
            </IonCard>

            </IonContent>
        </IonPage>
    )
}
export default CarbonationWatch