import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { arrowForward, arrowForwardCircle, handRight } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const Tab3 = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Calculation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid class='center-cols'>
          
          <IonRow>
            <IonCol size="6" className='calc-col' >
              <Link to={'/co2FlowRate'} className="none-link">
              Co2 Stone flow rate calculator
                {/* <IonButton expand='block' color="tertiary" >Co2 Stone flow rate calculator</IonButton> */}
                <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
              </Link>
            </IonCol>
            <IonCol size="6" className='calc-col'>
              <Link to={'/carbonationLevel'} className="none-link">
              Carbonation level checker
                {/* <IonButton expand='block' color="tertiary">Carbonation level checker</IonButton> */}
                <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
              </Link>
            </IonCol>
          </IonRow>
          
         
         
          <IonRow>
            <IonCol size="6" className='calc-col'>
              <Link className="none-link" to={'/bottleConditioning'}>
                {/* <IonButton expand='block' color="tertiary"> */}
                  Bottle Conditioning
                  {/* </IonButton> */}
                  <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
              </Link>
            </IonCol>
            <IonCol size="6" className='calc-col'>
              <Link className="none-link" to={'/abvBasic'}>
                {/* <IonButton expand='block' color="tertiary"> */}
                  ABV Basic
                  {/* </IonButton> */}
                  <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
              </Link>
            </IonCol>
          </IonRow>
          
          <IonRow>
            <IonCol size="6" className='calc-col'>
              <Link  className="none-link" to={'/iBUTinseth'}>
                {/* <IonButton expand='block' color="tertiary"> */}
                  IBU Tinseth
                  {/* </IonButton> */}
                  <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
              </Link>
            </IonCol>
            <IonCol size="6" className='calc-col'>
              <Link className="none-link" to={'/carbonationWatch'}>
                {/* <IonButton expand='block' color="tertiary"> */}
                {/* <span> */}
                Carbonation Watch and Learn
                {/* </span> */}
                 
                  {/* </IonButton> */}
                  <span>
                  <IonIcon className='arrow-style' slot="icon-only" icon={arrowForwardCircle} />
                  </span>
                 
              </Link>
            </IonCol>
          </IonRow>
          
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
